import React, { memo } from "react";
import styled from "styled-components";

const FlexButton = ({
  style,
  icon,
  text,
  backgroundColor,
  width,
  height,
  padding,
  margin,
  onClick,
}) => {
  return (
    <Button
      type="button"
      style={style}
      backgroundColor={backgroundColor}
      width={width}
      height={height}
      padding={padding}
      margin={margin}
      onClick={onClick}
    >
      <ContentWrap>
        {icon}
        {text}
      </ContentWrap>
    </Button>
  );
};

const Button = styled.button`
  display: flex;
  width: 100%;
  padding: 12px 0px;
  border-radius: 50px;
  border: none;
  background-color: ${({ theme, backgroundColor }) =>
    backgroundColor ? backgroundColor : theme.colors.defaultBackground};
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const ContentWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default memo(FlexButton);
