import { useCallback } from "react";
import { stringEn } from "./languages/en";
import { stringId } from "./languages/id";
import { stringKr } from "./languages/kr";

const LocalizationsLanguage = {
  id: stringId,
  kr: stringKr,
  en: stringEn,
};

export const useLocalizations = (country) => {
  const Lang = useCallback(
    (key) => {
      return LocalizationsLanguage?.[country ?? "id"]?.[key] ?? key;
    },
    [country]
  );

  return { Lang };
};
