import React, { memo } from "react";
import styled, { keyframes } from "styled-components";

const LOGO_WIDTH = 230;

const SliderComponent = memo(({ url }) => {
  return (
    <SlideBox width={LOGO_WIDTH}>
      <SlideItem src={url} alt="Partners-Logo" />
    </SlideBox>
  );
});

const LogoAutoSlider = ({ data, direction, style }) => {
  return (
    <Wrap style={style}>
      <Area width={LOGO_WIDTH} direction={direction} dataLength={data?.length}>
        {data?.map((item, index) => (
          <SliderComponent key={index} url={item?.url} />
        ))}
      </Area>
    </Wrap>
  );
};

export default memo(LogoAutoSlider);

const Wrap = styled.div`
  margin: 0 auto;
  overflow: hidden;
  width: 100%;
  height: auto;
  ::after {
    right: 0;
    top: 0;
    -webkit-transform: rotateZ(180deg);
    transform: rotateZ(180deg);
  }
  ::before {
    left: 0;
    top: 0;
  }
`;

const rigntAutoSlide = (x, dataLength) => keyframes`
  0% {
    transform: translateX(calc(${x}px * ${dataLength / 2}));
  }
  100% {
    transform: translateX(0);
  }
`;
const leftAutoSlide = (x, dataLength) => keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(${x}px * ${dataLength / 2}));
  }
`;

const Area = styled.div`
  display: flex;
  width: ${({ width, dataLength }) => `calc(${width}px * ${dataLength})`};
  animation: ${({ direction, dataLength, width }) =>
      direction === "right"
        ? rigntAutoSlide(-width, dataLength)
        : leftAutoSlide(-width, dataLength)}
    50s linear infinite;
  background-color: #ffff;
  @media screen and (max-width: 768px) {
    width: ${({ width, dataLength }) => `calc(${width / 2}px * ${dataLength})`};
    height: 18px;
    animation: ${({ width, direction, dataLength }) =>
        direction === "right"
          ? rigntAutoSlide(-width / 2, dataLength)
          : leftAutoSlide(-width / 2, dataLength)}
      50s linear infinite;
  }
`;

const SlideBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: ${({ width }) => `${width}px`};
  height: 40px;
  padding: 0 30px;
  @media screen and (max-width: 768px) {
    width: ${({ width }) => `${width / 2}px`};
    height: 18px;
    padding: 0 10px;
  }
`;

const SlideItem = styled.img`
  width: 100%;
  height: 40px;
  object-fit: contain;
  @media screen and (max-width: 768px) {
    height: 18px;
  }
`;
