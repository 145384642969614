export default {
  // ------ Community ------
  imgMyVenusThumbnail:
    "https://img.myvenusnetwork.io/product/2023/05/26813/source",
  iconDefaultProfile:
    "https://img.myvenusnetwork.io/product/2023/05/26874/source",
  imgDefaultThumbnail:
    "https://img.myvenusnetwork.io/product/2023/05/26917/source",
  iconStarFill: "https://img.myvenusnetwork.io/product/2023/05/26918/source",
  iconStarHalf: "https://img.myvenusnetwork.io/product/2023/05/26919/source",
  iconHeartFill: "https://img.myvenusnetwork.io/product/2023/05/26923/source",
  iconHeartStroke: "https://img.myvenusnetwork.io/product/2023/05/26924/source",
  iconHeartStrokeBold:
    "https://img.myvenusnetwork.io/product/2023/05/27022/source",
  iconHeartStrokeWhite:
    "https://img.myvenusnetwork.io/product/2023/05/27057/source",
  iconComment: "https://img.myvenusnetwork.io/product/2023/05/26926/source",
  iconCommentBold: "https://img.myvenusnetwork.io/product/2023/05/27019/source",
  iconShare: "https://img.myvenusnetwork.io/product/2023/05/26925/source",
  iconTagBold: "https://img.myvenusnetwork.io/product/2023/05/27020/source",
  iconViewBold: "https://img.myvenusnetwork.io/product/2023/05/27021/source",
  iconProfileLockSmall:
    "https://img.myvenusnetwork.io/product/2023/05/27024/source",
  iconProfileLockBig:
    "https://img.myvenusnetwork.io/product/2023/05/27025/source",
  iconBackButton: "https://img.myvenusnetwork.io/product/2023/05/27048/source",
  iconBackButtonWhite:
    "https://img.myvenusnetwork.io/product/2023/05/27056/source",
  iconShare2: "https://img.myvenusnetwork.io/product/2023/06/27449/source",
  iconShare2White: "https://img.myvenusnetwork.io/product/2023/05/27058/source",
  imgEmptyComment: "https://img.myvenusnetwork.io/product/2023/05/27317/source",
  imgQRDownloadBanner:
    "https://img.myvenusnetwork.io/product/2023/07/31104/source",
  imgQR: "https://img.myvenusnetwork.io/product/2023/06/28006/source",
  imgCoupon: "https://img.myvenusnetwork.io/product/2023/06/27510/source",

  // ------ IN ------
  imgHeader1: "https://img.myvenusnetwork.io/product/2023/05/26763/source",
  imgHeader2: "https://img.myvenusnetwork.io/product/2023/05/26764/source",
  imgMobileHeader1:
    "https://img.myvenusnetwork.io/product/2023/05/26765/source",
  imgMobileHeader2:
    "https://img.myvenusnetwork.io/product/2023/05/26766/source",
  imgContactBanner:
    "https://img.myvenusnetwork.io/product/2023/05/26767/source",
  imgMobileContactBanner:
    "https://img.myvenusnetwork.io/product/2023/05/26768/source",
  iconLogo: "https://img.myvenusnetwork.io/product/2023/06/28698/source",
  imgLogoPupple: "https://img.myvenusnetwork.io/product/2023/06/28722/source",
  imgWhiteLogo: "https://img.myvenusnetwork.io/product/2023/06/28700/source",
  imgColorfulLogo: "https://img.myvenusnetwork.io/product/2023/06/28701/source",
  iconGoogleStore: "https://img.myvenusnetwork.io/product/2023/06/28702/source",
  iconAppleStore: "https://img.myvenusnetwork.io/product/2023/06/28703/source",
  iconAppleStoreWhite:
    "https://img.myvenusnetwork.io/product/2023/06/28719/source",
  iconInstagram: "https://img.myvenusnetwork.io/product/2023/06/28704/source",
  iconYoutube: "https://img.myvenusnetwork.io/product/2023/06/28705/source",
  imgDiscount: "https://img.myvenusnetwork.io/product/2023/06/28716/source",
  imgChat: "https://img.myvenusnetwork.io/product/2023/06/28707/source",
  imgCalendar: "https://img.myvenusnetwork.io/product/2023/06/28708/source",
  imgMap: "https://img.myvenusnetwork.io/product/2023/06/28709/source",
  iconStar: "https://img.myvenusnetwork.io/product/2023/06/28710/source",
  imgTrendSmartPhone:
    "https://img.myvenusnetwork.io/product/2023/06/28711/source",
  imgTrendFeed: "https://img.myvenusnetwork.io/product/2023/06/28712/source",
  imgVenustalksSmartPhone:
    "https://img.myvenusnetwork.io/product/2023/06/28713/source",
  imgCommnunityList1:
    "https://img.myvenusnetwork.io/product/2023/06/28714/source",
  imgCommnunityList2:
    "https://img.myvenusnetwork.io/product/2023/06/28715/source",
  iconVtalk: "https://img.myvenusnetwork.io/product/2023/06/28717/source",
  iconCheckBoxActive:
    "https://img.myvenusnetwork.io/product/2023/06/28720/source",
  iconCheckBoxDisabled:
    "https://img.myvenusnetwork.io/product/2023/06/28721/source",
  imgContactIPhone:
    "https://img.myvenusnetwork.io/product/2023/06/28723/source",
  imgWhyMyVenus1: "https://img.myvenusnetwork.io/product/2023/06/28724/source",
  imgWhyMyVenus2: "https://img.myvenusnetwork.io/product/2023/06/28725/source",
  imgWhyMyVenus3: "https://img.myvenusnetwork.io/product/2023/06/28726/source",

  // ------ KR ------
  krMainVisual: "https://img.myvenusnetwork.io/product/2023/06/27894/source",
  krMobileVisual: "https://img.myvenusnetwork.io/product/2023/06/27895/source",
  krPhone1: "https://img.myvenusnetwork.io/product/2023/06/27923/source",
  krPhone2: "https://img.myvenusnetwork.io/product/2023/06/27924/source",
  krPhone3: "https://img.myvenusnetwork.io/product/2023/06/27925/source",
  krPhone4: "https://img.myvenusnetwork.io/product/2023/06/27926/source",
  krProfile1: "https://img.myvenusnetwork.io/product/2023/06/27929/source",
  krProfile2: "https://img.myvenusnetwork.io/product/2023/06/27930/source",
  krProfile3: "https://img.myvenusnetwork.io/product/2023/06/27931/source",
  krProfile4: "https://img.myvenusnetwork.io/product/2023/06/27932/source",
  krProfile5: "https://img.myvenusnetwork.io/product/2023/06/27933/source",
  krImg1: "https://img.myvenusnetwork.io/product/2023/06/27969/source",
  krImg2: "https://img.myvenusnetwork.io/product/2023/06/27970/source",
  krImg3: "https://img.myvenusnetwork.io/product/2023/06/27971/source",
  krImg4: "https://img.myvenusnetwork.io/product/2023/06/27973/source",
  krImg5: "https://img.myvenusnetwork.io/product/2023/06/27974/source",

  krConsultationBg:
    "https://img.myvenusnetwork.io/product/2023/06/28443/source",
  krConsultation1: "https://img.myvenusnetwork.io/product/2023/06/28444/source",
  krConsultation2: "https://img.myvenusnetwork.io/product/2023/06/28445/source",
  krLogoBanobagi: "https://img.myvenusnetwork.io/product/2023/06/28447/source",
  krLogoView: "https://img.myvenusnetwork.io/product/2023/06/28448/source",

  krReceipt1: "https://img.myvenusnetwork.io/product/2023/06/28461/source",
  krReceipt2: "https://img.myvenusnetwork.io/product/2023/06/28462/source",

  krNews1: "https://img.myvenusnetwork.io/product/2023/06/28541/source",
  krNews2: "https://img.myvenusnetwork.io/product/2023/06/28546/source",
  krNews3: "https://img.myvenusnetwork.io/product/2023/06/28547/source",
  krNews4: "https://img.myvenusnetwork.io/product/2023/06/28549/source",
  krNews5: "https://img.myvenusnetwork.io/product/2023/06/28550/source",
  krNews6: "https://img.myvenusnetwork.io/product/2023/06/28553/source",
  krNews7: "https://img.myvenusnetwork.io/product/2023/06/28554/source",
  krNews8: "https://img.myvenusnetwork.io/product/2023/06/28557/source",

  // ------ Renewal ------ //

  // ------ Home ------
  iconEarth: "https://img.myvenusnetwork.io/product/2023/09/33636/source",

  imgHomeBanner: "https://img.myvenusnetwork.io/product/2023/08/31278/source",
  imgHomeMobileBanner:
    "https://img.myvenusnetwork.io/product/2023/08/31288/source",

  iconRightArrow: "https://img.myvenusnetwork.io/product/2023/08/31376/source",
  iconRightWhiteArrow:
    "https://img.myvenusnetwork.io/product/2023/09/31377/source",
  iconLeftWhiteArrow:
    "https://img.myvenusnetwork.io/product/2023/09/31378/source",

  influencerImg1: "https://img.myvenusnetwork.io/product/2023/06/27974/source",
  influencerImg2: "https://img.myvenusnetwork.io/product/2023/06/27970/source",
  influencerImg3: "https://img.myvenusnetwork.io/product/2023/08/31371/source",
  influencerImg4: "https://img.myvenusnetwork.io/product/2023/06/27969/source",
  influencerImg5: "https://img.myvenusnetwork.io/product/2023/06/27971/source",
  influencerImg6: "https://img.myvenusnetwork.io/product/2023/06/27973/source",

  serviceReviewImg1:
    "https://img.myvenusnetwork.io/product/2023/09/31380/source",
  serviceReviewImg2:
    "https://img.myvenusnetwork.io/product/2023/09/31381/source",
  serviceReviewImg3:
    "https://img.myvenusnetwork.io/product/2023/09/31382/source",
  serviceReviewImg4:
    "https://img.myvenusnetwork.io/product/2023/09/31383/source",
  serviceReviewImg5:
    "https://img.myvenusnetwork.io/product/2023/09/31385/source",
  serviceReviewImg6:
    "https://img.myvenusnetwork.io/product/2023/09/31384/source",

  MVPhone1: "https://img.myvenusnetwork.io/product/2023/09/33349/source",
  MVPhone2: "https://img.myvenusnetwork.io/product/2023/09/33352/source",
  MVPhone3: "https://img.myvenusnetwork.io/staging/2023/09/33692/source",
  MVPhone4: "https://img.myvenusnetwork.io/staging/2023/09/33691/source",

  imgConciergeServiceBanner:
    "https://img.myvenusnetwork.io/product/2023/09/31396/source",
  imgConciergeServiceMobileBanner:
    "https://img.myvenusnetwork.io/product/2023/09/31428/source",

  DVSIntroductionImg1:
    "https://img.myvenusnetwork.io/product/2023/09/31397/source",
  DVSIntroductionImg2:
    "https://img.myvenusnetwork.io/product/2023/09/31398/source",
  DVSIntroductionImg3:
    "https://img.myvenusnetwork.io/product/2023/09/31401/source",
  DVSIntroductionImg4:
    "https://img.myvenusnetwork.io/product/2023/09/31400/source",

  imgFooterLogo: "https://img.myvenusnetwork.io/product/2023/09/31402/source",
  iconFooterSNSInstagram:
    "https://img.myvenusnetwork.io/product/2023/09/31403/source",
  iconFooterSNSYoutube:
    "https://img.myvenusnetwork.io/product/2023/09/31405/source",

  // ------ Service ------

  imgCircleLogo: "https://img.myvenusnetwork.io/product/2023/09/31411/source",
  imgConciergeServiceAboutBanner:
    "https://img.myvenusnetwork.io/product/2023/09/31412/source",
  imgConciergeServiceAboutMobileBanner:
    "https://img.myvenusnetwork.io/product/2023/09/31427/source",
  imgBubbleMessageLeft:
    "https://img.myvenusnetwork.io/product/2023/09/31408/source",
  imgBubbleMessageRight:
    "https://img.myvenusnetwork.io/product/2023/09/31409/source",
  imgPolygonRight: "https://img.myvenusnetwork.io/product/2023/09/31410/source",

  imgServiceTrustInductionVieweBanner:
    "https://img.myvenusnetwork.io/product/2023/09/31425/source",
  imgServiceTrustInductionVieweMobileBanner:
    "https://img.myvenusnetwork.io/product/2023/09/31426/source",

  imgServiceProcedureViewItem1:
    "https://img.myvenusnetwork.io/product/2023/09/31431/source",
  imgServiceProcedureViewItem2:
    "https://img.myvenusnetwork.io/product/2023/09/31434/source",
  imgServiceProcedureViewItem3:
    "https://img.myvenusnetwork.io/product/2023/09/31435/source",
  imgServiceProcedureViewItem4:
    "https://img.myvenusnetwork.io/product/2023/09/31436/source",
  imgServiceProcedureViewItem5:
    "https://img.myvenusnetwork.io/product/2023/09/31437/source",
  imgServiceProcedureViewItem6:
    "https://img.myvenusnetwork.io/product/2023/09/31438/source",
  imgServiceProcedureViewItem7:
    "https://img.myvenusnetwork.io/product/2023/09/31444/source",
  imgServiceProcedureViewItem8:
    " https://img.myvenusnetwork.io/product/2023/09/31443/source",
  imgServiceProcedureViewItem9:
    "https://img.myvenusnetwork.io/product/2023/09/31442/source",
  imgServiceProcedureViewItem10:
    "https://img.myvenusnetwork.io/product/2023/09/31441/source",
  imgServiceProcedureViewItem11:
    "https://img.myvenusnetwork.io/product/2023/09/31440/source",
  imgServiceProcedureViewItem12:
    "https://img.myvenusnetwork.io/product/2023/09/31439/source",
  iconServiceProcedureViewItemRigntArrow:
    "https://img.myvenusnetwork.io/product/2023/09/31430/source",
  iconServiceProcedureViewItemLeftArrow:
    "https://img.myvenusnetwork.io/product/2023/09/31433/source",
  iconServiceProcedureViewItemDownArrow:
    "https://img.myvenusnetwork.io/product/2023/09/31432/source",

  // ------ Consultations ------
  iconCheckoff: "https://img.myvenusnetwork.io/product/2023/09/31490/source",
  iconChevronDown: "https://img.myvenusnetwork.io/product/2023/09/31568/source",
  iconIndonesia: "https://img.myvenusnetwork.io/product/2023/09/31581/source",
  iconKorea: "https://img.myvenusnetwork.io/product/2023/09/31582/source",
  iconVietnam: "https://img.myvenusnetwork.io/product/2023/09/31583/source",

  iconUnitedStates:
    "https://img.myvenusnetwork.io/product/2023/09/33642/source",
  iconSingapore: "https://img.myvenusnetwork.io/product/2023/09/33643/source",
  iconAustralia: "https://img.myvenusnetwork.io/product/2023/09/33641/source",
  iconMalaysia: "https://img.myvenusnetwork.io/product/2023/09/33485/source",
  iconThailand: "https://img.myvenusnetwork.io/product/2023/09/33486/source",

  iconPicture: "https://img.myvenusnetwork.io/product/2023/09/33353/source",
  iconPlus: "https://img.myvenusnetwork.io/product/2023/09/33397/source",
  iconDeleteWhite: "https://img.myvenusnetwork.io/product/2023/09/33398/source",

  imgSurgicalSiteEyes:
    "https://img.myvenusnetwork.io/product/2023/09/31491/source",
  imgSurgicalSiteNose:
    "https://img.myvenusnetwork.io/product/2023/09/31492/source",
  imgSurgicalSiteFacialContouring:
    "https://img.myvenusnetwork.io/product/2023/09/31493/source",
  imgSurgicalSiteBreast:
    "https://img.myvenusnetwork.io/product/2023/09/31494/source",
  imgSurgicalSiteAntiAging:
    "https://img.myvenusnetwork.io/product/2023/09/31495/source",
  imgSurgicalSiteBody:
    "https://img.myvenusnetwork.io/product/2023/09/31496/source",
  imgSurgicalSiteHair:
    "https://img.myvenusnetwork.io/product/2023/09/31497/source",
  imgSurgicalSiteNonSurgical:
    "https://img.myvenusnetwork.io/product/2023/09/31498/source",
  imgUploadExample:
    "https://img.myvenusnetwork.io/staging/2023/09/33674/source",

  // ------ PriceList ------
  imgPriceList: "https://img.myvenusnetwork.io/product/2023/10/33701/source",
};
