import React from "react";
import Content from "./Content";
import NavBar from "./components/molecules/country/NavBar";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { theme, GlobalStyle } from "./styles/theme";
import Footer from "./components/molecules/country/Footer";

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <BrowserRouter>
        <NavBar />
        <Content />
        <Footer />
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
