import React, { memo, useCallback, useMemo } from "react";
import styled from "styled-components";

const NavbarToggle = ({
  collapsed,
  isNavBarTransparent,
  onClickMethod,
  setIsNavBarTransparent,
}) => {
  const isWhite = useMemo(
    () => isNavBarTransparent && collapsed,
    [isNavBarTransparent, collapsed]
  );

  const onClick = useCallback(() => {
    onClickMethod(!collapsed);
    setIsNavBarTransparent((prev) => !prev);
  }, [collapsed, onClickMethod, setIsNavBarTransparent]);

  return (
    <Toggle type="button" aria-label="Navbar Toggle" onClick={onClick}>
      <Line1 collapsed={collapsed} isNavBarTransparent={isWhite} />
      <Line2 collapsed={collapsed} isNavBarTransparent={isWhite} />
      {collapsed && (
        <Line2 collapsed={collapsed} isNavBarTransparent={isWhite} />
      )}
    </Toggle>
  );
};

const Toggle = styled.button`
  z-index: 4;
  background: transparent;
  outline: none;
  border: none;
  height: 40px;
  width: 46px;
`;

const Line1 = styled.span`
  display: block;
  width: 22px;
  height: 2px;
  background: ${({ isNavBarTransparent, theme }) =>
    !isNavBarTransparent
      ? theme.colors.BasicBlack
      : theme.colors.defaultBackground};
  border-radius: 4px;
  margin-top: 6px;
  margin-bottom: 6px;
  margin-left: auto;
  transition: transform 0.5s, margin 0.5s, background 0.5s;
  transform: ${({ collapsed }) => (collapsed ? "none" : "rotateZ(45deg)")};
  margin-top: ${({ collapsed }) => (collapsed ? "6px" : "-2px")};
  margin-bottom: ${({ collapsed }) => (collapsed ? "6px" : "-2px")};
`;

const Line2 = styled.span`
  display: block;
  width: 22px;
  height: 2px;
  background: ${({ isNavBarTransparent, theme }) =>
    !isNavBarTransparent
      ? theme.colors.BasicBlack
      : theme.colors.defaultBackground};
  border-radius: 4px;
  margin-top: 6px;
  margin-bottom: 6px;
  margin-left: auto;
  transition: transform 0.5s, margin 0.5s, background 0.5s;
  transform: ${({ collapsed }) => (collapsed ? "none" : "rotateZ(-45deg)")};
  margin-top: ${({ collapsed }) => (collapsed ? "6px" : "-2px")};
  margin-bottom: ${({ collapsed }) => (collapsed ? "6px" : "-2px")};
`;

export default memo(NavbarToggle);
