import React, { useCallback, useMemo } from "react";
import styled from "styled-components";
import { useMediaQuery } from "../../../hooks/useMediaQuery";

import images from "../../../assets/images";
import MVText from "../../atoms/text/MVText";
import { useLocalizations } from "../../../localizations";

const Footer = ({ language, countryCode, isEvent = false }) => {
  const isTablet = useMediaQuery(1024);
  const isMobile = useMediaQuery(768);
  const { Lang } = useLocalizations(countryCode);

  const logoWidth = useMemo(() => (isMobile ? 78 : 100), [isMobile]);
  const logoHeight = useMemo(() => (isMobile ? 14 : 18), [isMobile]);
  const SNSlogoWidth = useMemo(() => (isMobile ? 16 : 24), [isMobile]);
  const SNSlogoHeight = useMemo(() => (isMobile ? 16 : 24), [isMobile]);

  return (
    <Wrap>
      <FooterInfoBox>
        <Border />
        <BottomView>
          <FooterLogoBox>
            <FooterLogo
              src={images.imgFooterLogo}
              width={logoWidth}
              height={logoHeight}
              alt="footerlogo"
            />
            <MVText
              type={isTablet ? "body_5_regular" : "body_3_medium"}
              color="grayScale1000"
            >
              One-stop Solution for Your Cosmetic Surgery
            </MVText>
          </FooterLogoBox>
          <SNSLogoBox>
            <SNSIconButton
              style={{ marginRight: 16 }}
              onClick={() =>
                window.open("https://www.instagram.com/myvenus.io/", "_blank")
              }
            >
              <SNSLogo
                src={images.iconFooterSNSInstagram}
                width={SNSlogoWidth}
                height={SNSlogoHeight}
                alt="footerlogo"
              />
            </SNSIconButton>
            <SNSIconButton
              onClick={() =>
                window.open("https://www.youtube.com/@myvenus.tv.", "_blank")
              }
            >
              <SNSLogo
                src={images.iconFooterSNSYoutube}
                width={SNSlogoWidth}
                height={SNSlogoHeight}
                alt="footerlogo"
              />
            </SNSIconButton>
          </SNSLogoBox>
        </BottomView>
      </FooterInfoBox>
    </Wrap>
  );
};

const Wrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #f9fafe;
`;

const FooterInfoBox = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 38px 0px;
  @media only screen and (max-width: 1440px) {
    max-width: 100%;
    padding: 20px 20px 40px 20px;
  }
`;

const TopView = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  @media only screen and (max-width: 1440px) {
    flex-direction: column;
  }
`;

const BottomView = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  @media only screen and (max-width: 1024px) {
    flex-direction: column;
  }
`;

const Border = styled.div`
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.grayScale300}`};
`;

const NavButton = styled.button`
  border: none;
  background-color: #f9fafe;
  cursor: pointer;
`;

const NavListBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  @media only screen and (max-width: 1440px) {
    margin-bottom: 10px;
  }
`;

const StoreBtnText = styled.span`
  color: ${({ theme }) => theme.colors.primaryMain};
  font-family: "Poppins-Bold";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  @media only screen and (max-width: 768px) {
    font-size: 11px;
  }
`;

const StoreBtnListBox = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FooterLogoBox = styled.div`
  display: flex;
  align-items: center;
  @media only screen and (max-width: 1024px) {
    flex-direction: column;
    margin-bottom: 20px;
  }
`;

const FooterLogo = styled.img`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 24px;
  @media only screen and (max-width: 1024px) {
    margin-bottom: 8px;
    margin-right: 0px;
  }
`;

const SNSLogoBox = styled.div``;

const SNSLogo = styled.img``;

const SNSIconButton = styled.a`
  cursor: pointer;
`;

export default Footer;
