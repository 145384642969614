import React, { memo, useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import images from "../../../assets/images";
import MVText from "../../atoms/text/MVText";

const NationSelectButton = ({ boxStyle, countryCode, setNavbarCollapsed }) => {
  const [currentNation, setCurrentNation] = useState("id");
  const [isOpen, setIsOpen] = useState(false);

  const SELET_LIST = useMemo(
    () => [
      {
        label: "Indonesia",
        value: "id",
      },
      {
        label: "Tiếng Việt",
        value: "vn",
      },
    ],
    []
  );

  const onClick = useCallback(
    (value) => {
      setCurrentNation(value);
      setIsOpen((prev) => !prev);
      setNavbarCollapsed && setNavbarCollapsed((prev) => !prev);
    },
    [setNavbarCollapsed]
  );

  return (
    <ButtonBox>
      <Button style={boxStyle} onClick={() => setIsOpen((prev) => !prev)}>
        <EarthIcon
          src={images.iconEarth}
          width={24}
          height={24}
          alt="Icon Earth"
        />
        <ButtonText>{"Indonesia" || ""}</ButtonText>
        <ChevronDownIcon
          src={images.iconChevronDown}
          width={24}
          height={24}
          alt="Icon Chevron Down"
        />
      </Button>

      {isOpen && (
        <SelectBox>
          {SELET_LIST?.map((item, index) => (
            <SelectTextButton key={index} onClick={() => onClick(item?.value)}>
              <MVText
                type="body_2_regular"
                color={
                  item?.value === currentNation ? "primaryMain" : "BasicBlack"
                }
                align="center"
              >
                {item?.label || ""}
              </MVText>
            </SelectTextButton>
          ))}
        </SelectBox>
      )}
    </ButtonBox>
  );
};

const ButtonBox = styled.div`
  position: relative;
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  border: none;
  padding: 0;
  background-color: ${({ theme }) => theme.colors.defaultBackground};
  cursor: pointer;
`;

const ButtonText = styled.span`
  ${({ theme }) => theme.fonts.body_2_bold};
  color: ${({ theme }) => theme.colors.BasicBlack};
  @media only screen and (max-width: 1335px) {
    ${({ theme }) => theme.fonts.body_3_bold};
  }
`;

const EarthIcon = styled.img`
  margin-right: 6px;
  @media only screen and (max-width: 1335px) {
    margin-right: 4px;
    width: 18px;
    height: 18px;
  }
`;

const ChevronDownIcon = styled.img`
  margin-left: 6px;
`;

const SelectBox = styled.div`
  box-sizing: border-box;
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 40px;
  width: 142px;
  border-radius: 8px;
  border: 1px solid var(--primary-700-main, #7a23df);
  background: var(--basic-white, #fff);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.08);
  padding: 0px 10px;
`;

const SelectTextButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 13px 21px;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.grayScale300}`};
  background-color: ${({ theme }) => theme.colors.defaultBackground};
  cursor: pointer;
`;

export default memo(NationSelectButton);
