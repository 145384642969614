import React, { memo } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const BigButton = ({ style, icon, text, backgroundColor, onClick }) => {
  return (
    <Button
      type="button"
      style={style}
      backgroundColor={backgroundColor}
      onClick={onClick}
    >
      <ContentWrap>
        {icon}
        {text}
      </ContentWrap>
    </Button>
  );
};

BigButton.propTypes = {
  style: PropTypes.object,
  text: PropTypes.node.isRequired,
  fontColor: PropTypes.string,
  icon: PropTypes.node,
  backgroundColor: PropTypes.string,
  onClick: PropTypes.func,
};

const Button = styled.button`
  display: flex;
  width: 220px;
  height: 68px;
  border-radius: 50px;
  border: none;
  color: ${({ fontColor, theme }) =>
    fontColor ? fontColor : theme.colors.BasicBlack};
  background-color: ${({ theme, backgroundColor }) =>
    backgroundColor ? backgroundColor : theme.colors.defaultBackground};
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const ContentWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default memo(BigButton);
