import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { useMediaQuery } from "./hooks/useMediaQuery";
import { getUtmParams } from "./utils/common";

import { ColorTypes, theme } from "./styles/theme";
import images from "./assets/images";
import FlexButton from "./components/atoms/button/FlexButton";
import MVText from "./components/atoms/text/MVText";
import { useLocalizations } from "./localizations";
import BigButton from "./components/atoms/button/BigButton";
import LogoAutoSlider from "./components/molecules/country/LogoAutoSlider";
import InfluencerListItem from "./components/molecules/country/InfluencerListItem";
import InfluencerSwipeItem from "./components/molecules/country/InfluencerSwipeItem";
import DVSIntroductionListItem from "./components/molecules/country/DVSIntroductionListItem";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation } from "swiper/modules";
import MediumButton from "./components/atoms/button/MediumButton";
import Row from "./components/molecules/Row";

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";

const Content = ({ countryCode }) => {
  const isTablet = useMediaQuery(1024);
  const isMobile = useMediaQuery(768);
  const { Lang } = useLocalizations(countryCode);

  const [phoneIndex, setPhoneIndex] = useState(0);
  const [isMount, setIsMount] = useState(false);

  useEffect(() => {
    setIsMount(true);
  }, []);

  const LOGO_SLIDER_1 = useMemo(
    () => [
      { url: "https://img.myvenusnetwork.io/product/2023/08/31328/source" },
      { url: "https://img.myvenusnetwork.io/product/2023/08/31329/source" },
      { url: "https://img.myvenusnetwork.io/product/2023/08/31330/source" },
      { url: "https://img.myvenusnetwork.io/product/2023/08/31331/source" },
      { url: "https://img.myvenusnetwork.io/product/2023/08/31332/source" },
      { url: "https://img.myvenusnetwork.io/product/2023/08/31333/source" },
      { url: "https://img.myvenusnetwork.io/product/2023/08/31334/source" },
      { url: "https://img.myvenusnetwork.io/product/2023/08/31335/source" },
      { url: "https://img.myvenusnetwork.io/product/2023/08/31336/source" },
      { url: "https://img.myvenusnetwork.io/product/2023/08/31337/source" },
      { url: "https://img.myvenusnetwork.io/product/2023/08/31338/source" },
      { url: "https://img.myvenusnetwork.io/product/2023/08/31339/source" },
      { url: "https://img.myvenusnetwork.io/product/2023/08/31340/source" },
      { url: "https://img.myvenusnetwork.io/product/2023/08/31341/source" },
      { url: "https://img.myvenusnetwork.io/product/2023/08/31328/source" },
      { url: "https://img.myvenusnetwork.io/product/2023/08/31329/source" },
      { url: "https://img.myvenusnetwork.io/product/2023/08/31330/source" },
      { url: "https://img.myvenusnetwork.io/product/2023/08/31331/source" },
      { url: "https://img.myvenusnetwork.io/product/2023/08/31332/source" },
      { url: "https://img.myvenusnetwork.io/product/2023/08/31333/source" },
      { url: "https://img.myvenusnetwork.io/product/2023/08/31334/source" },
      { url: "https://img.myvenusnetwork.io/product/2023/08/31335/source" },
      { url: "https://img.myvenusnetwork.io/product/2023/08/31336/source" },
      { url: "https://img.myvenusnetwork.io/product/2023/08/31337/source" },
      { url: "https://img.myvenusnetwork.io/product/2023/08/31338/source" },
      { url: "https://img.myvenusnetwork.io/product/2023/08/31339/source" },
      { url: "https://img.myvenusnetwork.io/product/2023/08/31340/source" },
      { url: "https://img.myvenusnetwork.io/product/2023/08/31341/source" },
    ],
    []
  );

  const LOGO_SLIDER_2 = useMemo(
    () => [
      { url: "https://img.myvenusnetwork.io/product/2023/08/31342/source" },
      { url: "https://img.myvenusnetwork.io/product/2023/08/31343/source" },
      { url: "https://img.myvenusnetwork.io/product/2023/08/31344/source" },
      { url: "https://img.myvenusnetwork.io/product/2023/08/31345/source" },
      { url: "https://img.myvenusnetwork.io/product/2023/08/31346/source" },
      { url: "https://img.myvenusnetwork.io/product/2023/08/31347/source" },
      { url: "https://img.myvenusnetwork.io/product/2023/08/31348/source" },
      { url: "https://img.myvenusnetwork.io/product/2023/08/31349/source" },
      { url: "https://img.myvenusnetwork.io/product/2023/08/31350/source" },
      { url: "https://img.myvenusnetwork.io/product/2023/08/31351/source" },
      { url: "https://img.myvenusnetwork.io/product/2023/08/31353/source" },
      { url: "https://img.myvenusnetwork.io/product/2023/08/31354/source" },
      { url: "https://img.myvenusnetwork.io/product/2023/08/31355/source" },
      { url: "https://img.myvenusnetwork.io/product/2023/08/31356/source" },
      { url: "https://img.myvenusnetwork.io/product/2023/08/31342/source" },
      { url: "https://img.myvenusnetwork.io/product/2023/08/31343/source" },
      { url: "https://img.myvenusnetwork.io/product/2023/08/31344/source" },
      { url: "https://img.myvenusnetwork.io/product/2023/08/31345/source" },
      { url: "https://img.myvenusnetwork.io/product/2023/08/31346/source" },
      { url: "https://img.myvenusnetwork.io/product/2023/08/31347/source" },
      { url: "https://img.myvenusnetwork.io/product/2023/08/31348/source" },
      { url: "https://img.myvenusnetwork.io/product/2023/08/31349/source" },
      { url: "https://img.myvenusnetwork.io/product/2023/08/31350/source" },
      { url: "https://img.myvenusnetwork.io/product/2023/08/31351/source" },
      { url: "https://img.myvenusnetwork.io/product/2023/08/31353/source" },
      { url: "https://img.myvenusnetwork.io/product/2023/08/31354/source" },
      { url: "https://img.myvenusnetwork.io/product/2023/08/31355/source" },
      { url: "https://img.myvenusnetwork.io/product/2023/08/31356/source" },
    ],
    []
  );

  const LOGO_SLIDER_3 = useMemo(
    () => [
      { url: "https://img.myvenusnetwork.io/product/2023/08/31357/source" },
      { url: "https://img.myvenusnetwork.io/product/2023/08/31358/source" },
      { url: "https://img.myvenusnetwork.io/product/2023/08/31359/source" },
      { url: "https://img.myvenusnetwork.io/product/2023/08/31360/source" },
      { url: "https://img.myvenusnetwork.io/product/2023/08/31361/source" },
      { url: "https://img.myvenusnetwork.io/product/2023/08/31362/source" },
      { url: "https://img.myvenusnetwork.io/product/2023/08/31363/source" },
      { url: "https://img.myvenusnetwork.io/product/2023/08/31364/source" },
      { url: "https://img.myvenusnetwork.io/product/2023/08/31365/source" },
      { url: "https://img.myvenusnetwork.io/product/2023/08/31366/source" },
      { url: "https://img.myvenusnetwork.io/product/2023/08/31367/source" },
      { url: "https://img.myvenusnetwork.io/product/2023/08/31368/source" },
      { url: "https://img.myvenusnetwork.io/product/2023/08/31369/source" },
      { url: "https://img.myvenusnetwork.io/product/2023/08/31370/source" },
      { url: "https://img.myvenusnetwork.io/product/2023/08/31357/source" },
      { url: "https://img.myvenusnetwork.io/product/2023/08/31358/source" },
      { url: "https://img.myvenusnetwork.io/product/2023/08/31359/source" },
      { url: "https://img.myvenusnetwork.io/product/2023/08/31360/source" },
      { url: "https://img.myvenusnetwork.io/product/2023/08/31361/source" },
      { url: "https://img.myvenusnetwork.io/product/2023/08/31362/source" },
      { url: "https://img.myvenusnetwork.io/product/2023/08/31363/source" },
      { url: "https://img.myvenusnetwork.io/product/2023/08/31364/source" },
      { url: "https://img.myvenusnetwork.io/product/2023/08/31365/source" },
      { url: "https://img.myvenusnetwork.io/product/2023/08/31366/source" },
      { url: "https://img.myvenusnetwork.io/product/2023/08/31367/source" },
      { url: "https://img.myvenusnetwork.io/product/2023/08/31368/source" },
      { url: "https://img.myvenusnetwork.io/product/2023/08/31369/source" },
      { url: "https://img.myvenusnetwork.io/product/2023/08/31370/source" },
    ],
    []
  );

  const INFLUENCER_LIST = useMemo(
    () => [
      {
        name: "Jessica Iskandar",
        descrption: Lang("home.influencer.descrption1"),
        linkUrl: "https://youtu.be/WrRfKCT4AF4",
        img: images.influencerImg2,
      },
      {
        name: "Titan Tyra",
        descrption: Lang("home.influencer.descrption2"),
        linkUrl: "https://youtu.be/UhAxs4YzKMY",
        img: images.influencerImg6,
      },
      {
        name: "Jessica Mila",
        descrption: Lang("home.influencer.descrption3"),
        linkUrl:
          "https://www.instagram.com/p/B9QtRkrAnAp/?igshid=MzRlODBiNWFlZA==",
        img: images.influencerImg3,
      },
      {
        name: "Natasha Wilona",
        descrption: Lang("home.influencer.descrption4"),
        linkUrl: "https://youtu.be/q7pj5qF_7FE",
        img: images.influencerImg4,
      },
      {
        name: "Agista",
        descrption: Lang("home.influencer.descrption5"),
        linkUrl: "https://www.instagram.com/p/CjINcQSP8m1/?img_index=1",
        img: images.influencerImg1,
      },
      {
        name: "Molita lin",
        descrption: Lang("home.influencer.descrption6"),
        linkUrl: "https://youtu.be/Rah1_zOOTgM",
        img: images.influencerImg5,
      },
    ],
    []
  );

  const SERVICE_REVIEW_IMAGES = useMemo(
    () => [
      images.serviceReviewImg1,
      images.serviceReviewImg2,
      images.serviceReviewImg3,
      images.serviceReviewImg4,
      images.serviceReviewImg5,
      images.serviceReviewImg6,
      images.serviceReviewImg1,
      images.serviceReviewImg2,
      images.serviceReviewImg3,
      images.serviceReviewImg4,
      images.serviceReviewImg5,
      images.serviceReviewImg6,
      images.serviceReviewImg1,
      images.serviceReviewImg2,
      images.serviceReviewImg3,
      images.serviceReviewImg4,
      images.serviceReviewImg5,
      images.serviceReviewImg6,
    ],
    []
  );

  const MYVENUS_APP_IMAGE_LIST = useMemo(() => {
    return [
      {
        img: images.MVPhone1,
        imgAlt: Lang("home.myvenusApp.list.image.title1"),
        title: Lang("home.myvenusApp.list.image.title1"),
        description: Lang("home.myvenusApp.list.image.description1"),
      },
      {
        img: images.MVPhone2,
        imgAlt: Lang("home.myvenusApp.list.image.title2"),
        title: Lang("home.myvenusApp.list.image.title2"),
        description: Lang("home.myvenusApp.list.image.description2"),
      },
      {
        img: images.MVPhone3,
        imgAlt: Lang("home.myvenusApp.list.image.title3"),
        title: Lang("home.myvenusApp.list.image.title3"),
        description: Lang("home.myvenusApp.list.image.description3"),
      },
      {
        img: images.MVPhone4,
        imgAlt: Lang("home.myvenusApp.list.image.title4"),
        title: Lang("home.myvenusApp.list.image.title4"),
        description: Lang("home.myvenusApp.list.image.description4"),
      },
    ];
  }, []);

  const DVS_INTRODUCTION_LIST = useMemo(() => {
    return [
      {
        img: images.DVSIntroductionImg1,
        imgAlt: Lang("home.dvs.introduction.list.title1"),
        title: Lang("home.dvs.introduction.list.title1"),
        description: Lang("home.dvs.introduction.list.description1"),
      },
      {
        img: images.DVSIntroductionImg2,
        imgAlt: Lang("home.dvs.introduction.list.title2"),
        title: Lang("home.dvs.introduction.list.title2"),
        description: Lang("home.dvs.introduction.list.description2"),
      },
      {
        img: images.DVSIntroductionImg3,
        imgAlt: Lang("home.dvs.introduction.list.title3"),
        title: Lang("home.dvs.introduction.list.title3"),
        description: Lang("home.dvs.introduction.list.description3"),
      },
      {
        img: images.DVSIntroductionImg4,
        imgAlt: Lang("home.dvs.introduction.list.title4"),
        title: Lang("home.dvs.introduction.list.title4"),
        description: Lang("home.dvs.introduction.list.description4"),
      },
    ];
  }, []);

  const onClickGoogleStoreLink = useCallback(() => {
    const url = "https://play.google.com/store/apps/details?id=io.myvenus";
    const { utm_source, utm_campaign } = getUtmParams(window.location.search);
    const utm =
      utm_source && utm_campaign
        ? `&utm_source=${utm_source}&utm_campaign=${utm_campaign}`
        : "";
    window.open(url + utm);
  }, []);

  const onClickAppleStoreLink = useCallback(() => {
    const { utm_source, utm_campaign } = getUtmParams(window.location.search);
    let url =
      "https://apps.apple.com/id/app/myvenus-beauty-wellness/id1612742926";
    if (utm_source && utm_campaign) {
      url = `https://apps.apple.com/app/apple-store/id1612742926?pt=124192865&ct=${utm_source}.${utm_campaign}&mt=8`;
    }
    window.open(url);
  }, []);

  return (
    <Wrapper>
      {/* 홈 배너 */}
      <BannerSection>
        <BannerImage src={images.imgHomeBanner} alt="Banner Image" />
        <BannerMobileImage
          src={images.imgHomeMobileBanner}
          alt="Banner Image"
        />
        <BannerInfoArea>
          <BannerTitle>
            Top-tier Plastic Surgery Doctors in Our Network
          </BannerTitle>
          <BannerSubTitle>{Lang("home.banner.subTitle")}</BannerSubTitle>

          {/* <ButtonsBox>
            <BigButton
              style={{ marginRight: "20px" }}
              text={<ButtonText>Google play</ButtonText>}
              icon={
                <GoogleStoreIcon
                  src={images.iconGoogleStore}
                  width={40}
                  height={40}
                  alt="iconAppleStore"
                />
              }
              onClick={onClickGoogleStoreLink}
            />
            <BigButton
              text={<ButtonText>App store</ButtonText>}
              icon={
                <AppleStoreIcon
                  src={images.iconAppleStore}
                  width={25}
                  height={30}
                  alt="iconAppleStore"
                />
              }
              onClick={onClickAppleStoreLink}
            />
          </ButtonsBox> */}
        </BannerInfoArea>
      </BannerSection>

      {/* 병원 파트너 로고 Auto 슬라이더 */}
      <PartnersLogoSection>
        <PartnersLogoTitleArea>
          <h2>
            <MVText
              style={{ padding: isTablet ? "0px 20px" : 0 }}
              align="center"
              type={isMobile ? "subtitle_4_bold" : "subtitle_1_bold"}
              color="BasicBlack"
            >
              {Lang("home.partners.logo.title")}
            </MVText>
          </h2>
        </PartnersLogoTitleArea>
        <LogoAutoSlider
          style={{ paddingBottom: isMobile ? 30 : 70 }}
          data={LOGO_SLIDER_1}
          direction={"right"}
        />
        <LogoAutoSlider
          style={{ paddingBottom: isMobile ? 30 : 70 }}
          data={LOGO_SLIDER_2}
          direction={"left"}
        />
        <LogoAutoSlider data={LOGO_SLIDER_3} direction={"right"} />
      </PartnersLogoSection>

      {/* 인플루언서 소개 리스트 */}
      <InfluencerListSection>
        <InfluencerListTitleArea>
          <h2>
            <MVText
              type={isMobile ? "subtitle_4_bold" : "subtitle_1_bold"}
              color="BasicBlack"
            >
              {Lang("home.influencer.title")}
            </MVText>
          </h2>
        </InfluencerListTitleArea>
        {isMobile ? (
          <Swiper
            slidesPerView="auto"
            spaceBetween={20}
            freeMode
            modules={[FreeMode, Navigation]}
            className="mySwiper"
            slidesOffsetAfter={20}
            slidesOffsetBefore={20}
          >
            {INFLUENCER_LIST.map((item, index) => {
              return (
                <SwiperSlide key={index} style={{ width: 200 }}>
                  <InfluencerSwipeItem data={item} countryCode={countryCode} />
                </SwiperSlide>
              );
            })}
          </Swiper>
        ) : (
          <InfluencerListItemArea>
            {INFLUENCER_LIST.map((item, index) => {
              return (
                <InfluencerListItem
                  key={index}
                  data={item}
                  countryCode={countryCode}
                />
              );
            })}
          </InfluencerListItemArea>
        )}
      </InfluencerListSection>

      {/* 고객 서비스 리뷰 리스트 */}
      <ServiceReviewListSection>
        <ServiceReviewListTopArea>
          <h2>
            {isMobile ? (
              <div
                style={{
                  padding: "0px 39px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <MVText
                  style={{ marginBottom: 10 }}
                  type="body_3_regular"
                  color="defaultBackground"
                  align="center"
                >
                  {Lang("home.service.reviewList.title")}
                </MVText>
                <MVText
                  type="body_1_bold"
                  color="defaultBackground"
                  align="center"
                >
                  {Lang("home.service.reviewList.subTitle")}
                </MVText>
              </div>
            ) : (
              <div style={{ width: isTablet ? 600 : 877 }}>
                <MVText
                  style={{ marginBottom: 20 }}
                  type={"subtitle_3_regular"}
                  color="defaultBackground"
                >
                  {Lang("home.service.reviewList.title")}
                </MVText>
                <MVText
                  type={isTablet ? "subtitle_2_bold" : "subtitle_1_bold"}
                  color="defaultBackground"
                >
                  {Lang("home.service.reviewList.subTitle")}
                </MVText>
              </div>
            )}
          </h2>

          <SwiperNavigationBox>
            <SwiperNavigationBtn
              className="custom-prev"
              style={{ marginRight: 24 }}
            >
              <SwiperNavigationIcon
                src={images.iconLeftWhiteArrow}
                width={24}
                height={24}
                alt={"iconLeftWhiteArrow"}
              />
            </SwiperNavigationBtn>
            <SwiperNavigationBtn className="custom-next">
              <SwiperNavigationIcon
                src={images.iconRightWhiteArrow}
                width={24}
                height={24}
                alt={"iconRightWhiteArrow"}
              />
            </SwiperNavigationBtn>
          </SwiperNavigationBox>
        </ServiceReviewListTopArea>

        <div style={{ paddingBottom: isMobile ? 60 : 120 }}>
          <ServiceReviewListMobileSwiperBox
            slidesPerView="auto"
            spaceBetween={20}
            lazyPreloadPrevNext={3}
            slidesOffsetAfter={16}
            slidesOffsetBefore={16}
            navigation={{
              nextEl: ".custom-next-mobile",
              prevEl: ".custom-prev-mobile",
            }}
            loop={false}
            modules={[FreeMode, Navigation]}
            className="mySwiper"
          >
            {SERVICE_REVIEW_IMAGES.map((item, index) => {
              return (
                <SwiperSlide
                  key={index}
                  style={{
                    width: 164,
                    height: 164,
                    overflow: "hidden",
                    borderRadius: "25px",
                    pointerEvents: "auto",
                  }}
                >
                  <ServiceReviewListImage
                    src={item}
                    width={164}
                    height={164}
                    alt="Service Review Image"
                  />
                </SwiperSlide>
              );
            })}

            <SwiperNavigationMobilBox style={{ pointerEvents: "auto" }}>
              <SwiperNavigationMobileBtn
                className="custom-prev-mobile"
                style={{ marginRight: 24 }}
              >
                <SwiperNavigationIcon
                  src={images.iconLeftWhiteArrow}
                  width={40}
                  height={40}
                  alt={"iconLeftWhiteArrow"}
                />
              </SwiperNavigationMobileBtn>
              <SwiperNavigationMobileBtn className="custom-next-mobile">
                <SwiperNavigationIcon
                  src={images.iconRightWhiteArrow}
                  width={40}
                  height={40}
                  alt={"iconRightWhiteArrow"}
                />
              </SwiperNavigationMobileBtn>
            </SwiperNavigationMobilBox>
          </ServiceReviewListMobileSwiperBox>

          <ServiceReviewListSwiperBox
            slidesPerView="auto"
            spaceBetween={20}
            lazyPreloadPrevNext={3}
            slidesOffsetAfter={0}
            slidesOffsetBefore={0}
            breakpoints={{
              769: {
                slidesPerGroup: 5,
              },
            }}
            navigation={{
              nextEl: ".custom-next",
              prevEl: ".custom-prev",
            }}
            loop
            modules={[FreeMode, Navigation]}
            className="mySwiper"
          >
            {SERVICE_REVIEW_IMAGES.map((item, index) => {
              return (
                <SwiperSlide
                  key={index}
                  style={{
                    width: 345,
                    height: 345,
                    overflow: "hidden",
                    borderRadius: "25px",
                    pointerEvents: "auto",
                  }}
                >
                  <ServiceReviewListImage
                    src={item}
                    width={345}
                    height={345}
                    alt="Service Review Image"
                  />
                </SwiperSlide>
              );
            })}
          </ServiceReviewListSwiperBox>
        </div>
      </ServiceReviewListSection>

      {/* Concierge Service 페이지 이동 버튼 유도 뷰 */}
      <ConciergeServiceViewSection>
        <ConciergeServiceViewBannerImage
          src={
            isMobile
              ? images.imgConciergeServiceMobileBanner
              : images.imgConciergeServiceBanner
          }
          fill
          quality={100}
          alt="Concierge Service Banner Image"
        />
        <ConciergeServiceViewBannerInfoArea>
          <h2>
            <MVText
              style={{ marginBottom: 20 }}
              type={isMobile ? "subtitle_4_bold" : "subtitle_1_bold"}
              color="defaultBackground"
              align="center"
            >
              {Lang("home.conciergeService.view.title")}
            </MVText>
          </h2>
          <p>
            <MVText
              style={{ marginBottom: isMobile ? 0 : 20 }}
              type={isMobile ? "body_3_regular" : "body_1_regular"}
              color="defaultBackground"
              align="center"
            >
              {Lang("home.conciergeService.view.content.text1")}
            </MVText>
          </p>
          <p>
            <MVText
              type={isMobile ? "body_3_regular" : "body_1_regular"}
              color="defaultBackground"
              align="center"
            >
              {Lang("home.conciergeService.view.content.text2")}
            </MVText>
          </p>
          <ConciergeServiceLinkBox onClick={() => {}}>
            <MVText
              style={{ marginRight: 10 }}
              type={isMobile ? "button_2" : "body_1_bold"}
              color="defaultBackground"
            >
              {Lang("home.conciergeService.view.btn.text")}
            </MVText>
            <ConciergeServiceLinkArrowIcon
              src={images.iconRightWhiteArrow}
              width={isMobile ? 10 : 14}
              height={isMobile ? 10 : 14}
              alt="Concierge Service Arrow Icon"
            />
          </ConciergeServiceLinkBox>
        </ConciergeServiceViewBannerInfoArea>
      </ConciergeServiceViewSection>

      {/* 상담하기 버튼 뷰 */}
      <ConsultationsButtonViewSection>
        <ConsultationsButtonViewTitleArea>
          <MVText
            type={isMobile ? "subtitle_4_bold" : "subtitle_1_bold"}
            color="BasicBlack"
            align="center"
          >
            {Lang("home.consultations.button.view.title")}
          </MVText>
        </ConsultationsButtonViewTitleArea>
        <ConsultationsButtonViewButtonArea>
          {isMobile ? (
            <MediumButton
              style={{
                padding: "10px 22px",
                boxShadow: "0px 10px 20px 0px rgba(122, 35, 223, 0.25)",
              }}
              text={
                <Row align="center">
                  <MVText
                    style={{ marginRight: 5 }}
                    type="button_2"
                    color="defaultBackground"
                  >
                    {Lang("consultations.button.view.button.text")}
                  </MVText>
                  <ConciergeServiceLinkArrowIcon
                    src={images.iconRightWhiteArrow}
                    width={10}
                    height={10}
                    alt="Concierge Buttom View Arrow Icon"
                  />
                </Row>
              }
              fontColor={theme.colors.defaultBackground}
              backgroundColor={theme.colors.primaryMain}
              onClick={() => {
                window.open("https://wa.me/+821022800284", "_blank");
              }}
            />
          ) : (
            <MediumButton
              style={{
                padding: "16px 40px",
                boxShadow: "0px 10px 20px 0px rgba(122, 35, 223, 0.25)",
              }}
              text={
                <Row align="center">
                  <MVText
                    style={{ marginRight: 15 }}
                    type="body_1_bold"
                    color="defaultBackground"
                  >
                    {Lang("consultations.button.view.button.text")}
                  </MVText>
                  <ConsultationsButtonViewArrowIcon
                    src={images.iconRightWhiteArrow}
                    width={14}
                    height={14}
                    alt="Concierge Buttom View Arrow Icon"
                  />
                </Row>
              }
              fontColor={theme.colors.defaultBackground}
              backgroundColor={theme.colors.primaryMain}
              onClick={() => {
                window.open("https://wa.me/+821022800284", "_blank");
              }}
            />
          )}
        </ConsultationsButtonViewButtonArea>
      </ConsultationsButtonViewSection>
      <FloatButtonBox>
        <FlexButton
          style={{
            padding: "10px 20px",
          }}
          text={
            <MVText type="body_1_bold" color="defaultBackground" align="center">
              {Lang("consultations.btnText")}
            </MVText>
          }
          backgroundColor={theme.colors.primaryMain}
          onClick={() => {
            window.open("https://wa.me/+821022800284", "_blank");
          }}
        />
      </FloatButtonBox>
    </Wrapper>
  );
};

export default memo(Content);

const Wrapper = styled.div`
  padding-top: 98px;
  overflow: hidden;
  @media screen and (max-width: 1024px) {
    padding-top: 0px;
  }
`;

const FloatButtonBox = styled.div`
  display: none;
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  @media screen and (max-width: 1024px) {
    display: block;
  }
`;

const ConsultationsButtonViewSection = styled.div``;

const ConsultationsButtonViewTitleArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
  padding: 0px 20px;
  @media screen and (max-width: 768px) {
    margin-bottom: 20px;
  }
`;

const ConsultationsButtonViewButtonArea = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 180px;
  @media screen and (max-width: 768px) {
    margin-bottom: 80px;
  }
`;

const ConsultationsButtonViewArrowIcon = styled.img`
  margin-bottom: 1px;
`;

const DVSIntroductionListSection = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  margin-bottom: 260px;
  max-width: 1440px;
  @media screen and (max-width: 768px) {
    margin-bottom: 120px;
    max-width: 100%;
  }
`;

const DVSIntroductionListTitleArea = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 80px;
  @media screen and (max-width: 768px) {
    margin-bottom: 40px;
  }
`;

const DVSIntroductionListArea = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  @media screen and (max-width: 768px) {
  }
`;

const ConciergeServiceViewSection = styled.section`
  position: relative;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  width: 100vw;
  height: 540px;
  margin-bottom: 160px;
  @media screen and (max-width: 768px) {
    height: 360px;
    margin-bottom: 60px;
  }
`;

const ConciergeServiceViewBannerInfoArea = styled.div`
  position: absolute;
  width: 1163px;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media screen and (max-width: 1300px) {
    width: 1024px;
  }
  @media screen and (max-width: 1024px) {
    width: 650px;
  }
  @media screen and (max-width: 768px) {
    width: 85%;
  }
`;

const ConciergeServiceViewBannerImage = styled.img`
  position: relative;
  object-fit: cover;
  width: 100vw;
  height: 540px;
`;

const ConciergeServiceLinkBox = styled.a`
  display: flex;
  align-items: center;
  margin-top: 40px;
  cursor: pointer;
  @media screen and (max-width: 768px) {
    margin-top: 20px;
  }
`;

const ConciergeServiceLinkArrowIcon = styled.img`
  margin-bottom: 1px;
`;

const MyvenusAppListSection = styled.section`
  width: 100%;
  margin: 0 auto;
  max-width: calc(1440px + 36px + 36px);
  margin-bottom: 160px;
  @media screen and (max-width: 1024px) {
    margin-bottom: 80px;
  }
`;

const MyVenusAppListArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 1024px) {
    .swiper-slide {
      img {
        transition: all 0.2s ease-in-out;
        transform: translate(0, 0px);
      }
    }
    .swiper-slide-active {
      img {
        transform: translate(0, -10px);
      }
    }
  }
`;

const MyvenusAppImageBox = styled.ul`
  display: flex;
  justify-content: space-between;
  height: 796px;
  overflow: hidden;
  @media screen and (max-width: 1024px) {
    height: auto;
    overflow-x: scroll;
  }
`;

const MyvenusAppImageContent = styled.li`
  flex: 1;
  margin-top: 80px;
  height: 554px;
  transition: all 0.2s;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({ active }) =>
    active &&
    `
    & {
    }
    img {
      transform: translate(0, -50px);  
    }
`}
  @media screen and (max-width: 1024px) {
    height: 404px;
    padding: 60px 12px 40px;

    img {
      transform: translate(0);
      width: 183px;
      height: auto;
    }
  }
`;

const Phone = styled.img`
  box-shadow: -5px 15px 20px 5px rgba(0, 0, 0, 0.1);
  border-radius: 42px;
  transform: translate(0, 0px);
  transition: all 0.2s ease-out;
  @media screen and (max-width: 1440px) {
    width: 183px;
    height: 372px;
  }
  @media screen and (max-width: 1024px) {
    transform: translate(0);
    width: 183px;
    height: auto;
  }
`;

const ServiceReviewListSection = styled.section`
  background-color: ${({ theme }) => theme.colors.grayScale900};
  width: 100%;
  height: auto;
  margin-bottom: 160px;
  @media screen and (max-width: 1024px) {
    margin-bottom: 60px;
  }
`;

const ServiceReviewListTopArea = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1440px;
  margin: 0 auto;
  padding: 120px 20px 60px 20px;
  @media screen and (max-width: 768px) {
    align-items: center;
    max-width: 100%;
    padding: 60px 0px 40px;
  }
`;

const ServiceReviewListSwiperBox = styled.div`
  display: flex;
  position: relative;
  pointer-events: none;
  ::before {
    background: linear-gradient(to right, #2d2b2f 0%, rgba(45, 43, 47, 0) 100%);
    content: "";
    height: 100%;
    position: absolute;
    width: 200px;
    z-index: 2;
    left: 0;
    top: 0;
    opacity: 0.8;
    @media screen and (max-width: 768px) {
      width: 70px;
    }
  }
  ::after {
    background: linear-gradient(to left, #2d2b2f 0%, rgba(45, 43, 47, 0) 100%);
    content: "";
    height: 100%;
    position: absolute;
    width: 200px;
    z-index: 2;
    right: 0;
    top: 0;
    opacity: 0.8;
    @media screen and (max-width: 768px) {
      width: 70px;
    }
  }
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const ServiceReviewListMobileSwiperBox = styled.div`
  display: none;
  position: relative;
  pointer-events: none;
  ::before {
    background: linear-gradient(to right, #2d2b2f 0%, rgba(45, 43, 47, 0) 100%);
    content: "";
    height: 100%;
    position: absolute;
    width: 200px;
    z-index: 2;
    left: 0;
    top: 0;
    opacity: 0.8;
    @media screen and (max-width: 768px) {
      width: 70px;
    }
  }
  ::after {
    background: linear-gradient(to left, #2d2b2f 0%, rgba(45, 43, 47, 0) 100%);
    content: "";
    height: 100%;
    position: absolute;
    width: 200px;
    z-index: 2;
    right: 0;
    top: 0;
    opacity: 0.8;
    @media screen and (max-width: 768px) {
      width: 70px;
    }
  }
  @media screen and (max-width: 768px) {
    display: flex;
  }
`;

const ServiceReviewListImage = styled.img``;

const SwiperNavigationBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const SwiperNavigationMobilBox = styled.div`
  display: none;
  position: absolute;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  z-index: 3;
  bottom: 66px;
  @media screen and (max-width: 768px) {
    display: flex;
  }
`;

const SwiperNavigationMobileBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  margin: 0px 16px;
  border-radius: 100px;
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.colors.defaultBackground};
  cursor: pointer;
`;
const SwiperNavigationBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  background-color: ${({ theme }) => theme.colors.grayScale900};
  border: 1px solid ${({ theme }) => theme.colors.defaultBackground};
  cursor: pointer;
`;

const SwiperNavigationIcon = styled.img`
  @media screen and (max-width: 768px) {
    width: 11px;
    height: 11px;
  }
`;

const InfluencerListSection = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  margin-top: 240px;
  margin-bottom: 105px;
  max-width: calc(1440px);
  padding: 0px 8px;
  @media screen and (max-width: 768px) {
    margin-top: 80px;
    padding: 0px;
    margin-bottom: 60px;
  }
`;

const InfluencerListTitleArea = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 40px;
  padding-left: 12px;
  @media screen and (max-width: 768px) {
    margin-bottom: 20px;
    justify-content: center;
  }
`;

const InfluencerListItemArea = styled.ul`
  display: flex;
  flex-wrap: wrap;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const PartnersLogoTitleArea = styled.div`
  display: flex;
  justify-content: center;
  align-self: center;
  margin-bottom: 80px;
  width: 1024px;
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
  @media screen and (max-width: 768px) {
    margin-bottom: 60px;
  }
`;

const PartnersLogoSection = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  margin-top: 160px;
  width: 100%;
  @media screen and (max-width: 768px) {
    margin-top: 60px;
  }
`;

const BannerSection = styled.section`
  position: relative;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  width: calc(100vw - 10%);
  min-height: 680px;
  @media screen and (max-width: 1024px) {
    width: 100%;
    min-height: 100vh;
  }
`;

const BannerImage = styled.img`
  object-fit: cover;
  border-radius: 40px;
  @media screen and (max-width: 1024px) {
    display: none;
  }
`;

const BannerMobileImage = styled.img`
  display: none;
  width: 100%;
  height: 100vh;
  object-fit: cover;
  @media screen and (max-width: 1024px) {
    display: flex;
  }
`;

const BannerInfoArea = styled.div`
  width: 1083px;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media screen and (max-width: 1300px) {
    width: 1024px;
  }
  @media screen and (max-width: 1024px) {
    width: 80%;
    top: 118px;
    transform: translate(-50%, 0);
  }
`;

const BannerTitle = styled.h2`
  ${({ theme }) => theme.fonts.h_1_bold}
  text-align: center;
  color: ${({ theme }) => theme.colors.defaultBackground};
  margin-bottom: 20px;
  @media screen and (max-width: 1024px) {
    ${({ theme }) => theme.fonts.h_3_bold}
  }
`;

const BannerSubTitle = styled.p`
  ${({ theme }) => theme.fonts.body_1_regular}
  text-align: center;
  color: ${({ theme }) => theme.colors.defaultBackground};
  @media screen and (max-width: 1024px) {
    ${({ theme }) => theme.fonts.body_3_regular}
  }
`;
const ButtonsBox = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 60px;
  @media screen and (max-width: 1024px) {
    display: none;
  }
`;

const ButtonsMobileArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 39px;
  z-index: 4;
  padding: 0px 20px;
`;

const ButtonText = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: ${({ theme, fontColor }) =>
    fontColor ? fontColor : theme.colors.BasicBlack};
  margin-top: 5px;
`;

const ButtonMobileText = styled.span`
  font-family: "Poppins-SemiBold";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: ${({ theme, fontColor }) =>
    fontColor ? fontColor : theme.colors.BasicBlack};
`;

const AppleStoreIcon = styled.img`
  margin-right: 17px;
`;

const GoogleStoreIcon = styled.img`
  margin-right: 10px;
`;

const AppleStoreMobileIcon = styled.img`
  margin-right: 14px;
`;

const GoogleStoreMobileIcon = styled.img`
  margin-right: 10px;
`;
