export const stringKr = {
  'consultations.btnText': '상담하기',
  'consultations.button.view.button.text': '상담하러 가기',
  'consultations.info.footer.button.text': '참가신청',

  //----- Home ----- //

  'home.banner.subTitle': '성형수술 후기, 수술상담 그리고 수술 후 관리까지 원스탑 솔루션',
  'home.partners.logo.title': '한국과 인도네시아 500개 이상의 병원 파트너와 함께하고 있습니다.',
  'home.influencer.title': 'MyVenus x Influencer',
  'home.influencer.descrption1':
    '처음에는 마이비너스 수술을 받으러 오시는 분들이 많아서 지인들 통해서 마이비너스에 대해 알게되었습니다.  지인들의 리뷰를 봤는데 자신감이 생겼습니다.  수술 결과와 서비스가 탁월하다는 것이 입증되었습니다. 고마워요 마이비너스!',
  'home.influencer.descrption2':
    '마이비너스 서비스 너무 좋아요! MyVenus는 우리가 직접 원장님을 만나고 상담할 수  수 있도록 원장님은 베트남으로 데려갔습니다. 멋지죠?! 우리는 병원과 의사의 선택에 대해 더욱 확신을 갖게 될 것입니다!',
  'home.influencer.descrption3':
    '한국에 있는 동안 엄마가 최고의 서비스를 받을 수 있도록 도와주신 마이 비너스 감사합니다!',
  'home.influencer.descrption4':
    '저를 한국에 초대해주신 마이비너스 감사합니다. 사장님이 겸손하시니까 아무튼 마이비너스 덕분에 정말 큰 도움을 받았습니다',
  'home.influencer.descrption5':
    '한국 일정 동안  MyVenus는 모든 면에서 제게 큰 도움이 되었습니다. 통역사도 항상 같이 다니니까 전혀 걱정을 느끼지 않습니다. 무슨 일이 생기면 즉시 도움을 받아 처리할 수 있습니다.',
  'home.influencer.descrption6':
    '우리는 MyVenus를 사용하여 모든 것을 준비합니다! 한국에서 비자, 호텔, 통역 등을 도와주는 의료관광 서비스 제공업체인데 모든 것이 정말 완벽합니다. 대표님도 의사이셔서 더 믿음이 가고, 한국 일류병원만 협업을 하고있습니다!',
  'influencer.contentsLink.text': '컨텐츠 바로가기',
  'home.service.reviewList.title': '수많은 고객들이 MyVenus 와 함께 하셨고 서비스에 만족하셨습니다',
  'home.service.reviewList.subTitle': 'MyVenus는 수술 성공을 위한 최고의 도우미입니다',
  'home.myvenusApp.list.title': 'MyVenus App 주요기능',
  'home.myvenusApp.list.image.title1': '다양한 병원과 의사 정보 비교',
  'home.myvenusApp.list.image.description1':
    '수많은 고객들이 MyVenus와 함께 하셨고 서비스에 만족하셨습니다. MyVenus 는 수술 성공을 위한 최고의 도우미입니다.',
  'home.myvenusApp.list.image.title2': 'Real 성형수술 후기',
  'home.myvenusApp.list.image.description2': '각 수술부위의 Real 성형수술 후기를 둘러보세요.',
  'home.myvenusApp.list.image.title3': '성형수술 상담',
  'home.myvenusApp.list.image.description3': 'MyVenus를 통해 원하는 의사 또는 병원과 자세한 수술 상담을 진행 해보세요.',
  'home.myvenusApp.list.image.title4': '프로모션',
  'home.myvenusApp.list.image.description4': '다양한 병원의 특별 프로모션 이벤트를 확인해보세요.',
  'home.conciergeService.view.title': 'Exclusive Medical Concierge Service',
  'home.conciergeService.view.content.text1':
    '한국에 성형수술을 가고 싶은데 어디서부터 어떻게 해야 할 지 모르겠나요? 최고의 의사를 찾고 싶나요? 수술 후 부작용이 두렵나요?',
  'home.conciergeService.view.content.text2':
    '고객의 성공적인 수술을 위하여 MyVenus는 Exclusive Medical Concierge Service 를 제공합니다.',
  'home.conciergeService.view.btn.text': '자세히 보기',
  'home.dvs.introduction.list.title':
    'MyVenus 의 엄격한 DVS(Doctor Verification Sistem)에 통과된 병원과 의사만이 함께합니다.',
  'home.dvs.introduction.list.title1': '의료진 점검',
  'home.dvs.introduction.list.description1':
    '의료진이 5년이상을 가진 성형수술의 전문가(일반인 의사아님)인지, 이전에 의료사고가 있었는지 확인하고 있습니다 ',
  'home.dvs.introduction.list.title2': '의료진 이역서',
  'home.dvs.introduction.list.description2':
    '의과대확교의 편지, 특수 외과 분야, 의학 세미나 및 기타 의학 협회의 인증을 확인하고 있습니다',
  'home.dvs.introduction.list.title3': 'Hospital emergency medical facility',
  'home.dvs.introduction.list.description3':
    '성형외과에 마취과 의사가 상주하고 있는지, 응급의료장비가 있는지 확인했습니다.',
  'home.dvs.introduction.list.title4': '병원 시설 점검',
  'home.dvs.introduction.list.description4':
    '병원에서 사용하는 의료기기 및 의약품이 보건복지부의 정식 승인을 받은 제품인지, 올바른 용량을 사용하고 있는지 확인하고 있습니다.',
  'home.consultations.button.view.title': '지금 당장 성형수술 상담을 받아보세요!',

  //----- conciergeService ----- //
  'conciergeService.about.title': '- 차원이 다른 MyVenus 만의 Exclusive Medical Concierge Service 를 소개합니다.',
  'conciergeService.about.title.split.text': 'Exclusive Medical Concierge Service',
  'conciergeService.about.msg.bubble.text1':
    '“한국에 성형수술하러 가고 싶지만, 막상 한국에서 어떤 의사를 선택해야 할 지 걱정이신가요?“',
  'conciergeService.about.msg.bubble.text2':
    '“언어 문제로 인해 한국 의사에게내 요구사항이 제대로 전달이 안될까 걱정이신가요?“',
  'conciergeService.about.msg.bubble.text3': '“한국에서 수술 후 도우미가 필요하신가요?“',
  'conciergeService.about.msg.bubble.text4': '“인도네시아 귀국 후에 문제가 생기면 어떻게 해야할지 걱정이신가요?“',
  'conciergeService.serviceTrust.induction.view.text1': '모든 고객을 수술 전과 후 그리고 끝까지 책임집니다.',
  'conciergeService.serviceTrust.induction.view.text2':
    'MyVenus 는 한국과 인도네시아 두 나라에 정식 회사가 존재합니다. 다른 회사와 비교 자체가 무의미합니다.',
  'conciergeService.ServiceProcedure.view.split.title': 'One-stop Solution For Cosmetic Surgery',
  'conciergeService.ServiceProcedure.view.title':
    '한국 의사와 상담, 수술예약, 비자발급부터,수술 후 정기적인 관리까지 One-stop Solution For Cosmetic Surgery을 제공해드립니다.',
  'conciergeService.ServiceProcedure.view.item.text1': '온-오프라인 상담 통번역 지원',
  'conciergeService.ServiceProcedure.view.item.text2': '성형외과 수술 예약서 작성',
  'conciergeService.ServiceProcedure.view.item.text3': '상담 후 견적서 수령',
  'conciergeService.ServiceProcedure.view.item.text4': '보증금 납부',
  'conciergeService.ServiceProcedure.view.item.text5': '한국 방문 비자 발급',
  'conciergeService.ServiceProcedure.view.item.text6': '항공권 및 호텔 예약',
  'conciergeService.ServiceProcedure.view.item.text7': '한국으로 출국',
  'conciergeService.ServiceProcedure.view.item.text8': '인천공항에서 고객 픽업 서비스',
  'conciergeService.ServiceProcedure.view.item.text9': '전문 통역 서비스',
  'conciergeService.ServiceProcedure.view.item.text10': '수술 후 집중케어',
  'conciergeService.ServiceProcedure.view.item.text11': '인도네시아로 출국 (고객 배웅 서비스)',
  'conciergeService.ServiceProcedure.view.item.text12': '인도네시아에서 사후 관리 서비스',
  'conciergeService.service.reviewList.title':
    '수많은 고객들이 MyVenus와 함께 하셨고 서비스에 만족하셨습니다. MyVenus 는 수술 성공을 위한 최고의 도우미입니다.',
  'conciergeService.consultations.button.view.title':
    '한국에서 성형수술해야 하는 경우 MyVenus 의 Medical Concierge Service 는 선택이 아니라 필수입니다. 지금 당장 MyVenus와 성형수술에 대해서 상담 받아보세요!',

  //----- consultations ----- //
  'consultations.title': 'Medical Form for Consultation Event',
  'consultations.lite.title': 'Medical Form',
  'consultations.subTitle':
    '메디컬폼 작성 후에는 상담회 참가비용 Rp.1,000,000 지불과 함께 신분증을 제시해주셔야만 예약이 최종확정 됩니다.',
  'consultations.lite.subTitle':
    '작성 후에는 MyVenus 상담원과 1차 간단 상담 후, 원하시는 한국 병원/의사와 상담 진행할 수 있도록 도와드립니다!',
  'consultations.info.text':
    '💡상담회 참석 신청을 해주셔서 감사드립니다! 이 메디컬폼을 작성해주시면 상담해주시는 원장님에게 미리 전달 해드릴 예정입니다. 더 퀄리티 높은 상담을 위해 작성을 부탁 드립니다. 감사합니다!',

  'consultations.wish.surgical.site.title': '원하는 수술 부위',
  'consultations.wish.surgical.site.eyes': 'Eyes',
  'consultations.wish.surgical.site.nose': 'Nose',
  'consultations.wish.surgical.site.facialContouring': 'Facial Contouring',
  'consultations.wish.surgical.site.breast': 'Breast',
  'consultations.wish.surgical.site.antiAging': 'Anti-aging',
  'consultations.wish.surgical.site.body': 'Body',
  'consultations.wish.surgical.site.hair': 'Hair',
  'consultations.wish.surgical.site.nonSurgical': 'Non-surgical',

  'consultations.is.plastuc.surgery.title': '성형수술(보톡스 및 필러 포함)을 받으신 적이 있으신가요?',
  'consultations.upload.surgical.result.title': '원하는 수술결과의 예시 사진을 업로드 해주세요.',
  'consultations.upload.face.title': '본인의 얼굴 사진을 업로드해주세요',
  'consultations.upload.face.halfSide': '45도 옆',
  'consultations.upload.face.front': '전면',
  'consultations.upload.face.side': '옆',
  'consultations.is.disease.title': '과거에 질병을 앓았거나 현재 앓고 계신 질병이 있으신가요?',
  'consultations.is.medicine.title': '정기적으로 약이나 건강 보조제를 복용하시나요?',
  'consultations.is.trip.korea.title': '향후 한국 방문 계획이 있으신가요?',
  'consultations.hospital.in.korea.title': '관심 있거나 과거에 상담한 적이 있는 한국의 특정 병원이 있다면 알려주세요.',
  'consultations.source.myvenus.title': '마이비너스를 어떻게 알게 되겼나요?',
  'consultations.input.content.placeholder': '내용을 입력해주세요',
  'consultations.email.placeholder': '이메일을 입력해주세요',
  'consultations.name.title': '이름',
  'consultations.name.placeholder': '이름을 입력해주세요 (여권에 기재된 이름)',
  'consultations.phone.title': '휴대폰 번호',
  'consultations.phone.placeholder': '휴대폰 번호를 입력해주세요',
  'consultations.birthday.title': '생년월일',
  'consultations.birthday.placeholder': '생년월일을 입력해주세요',
  'consultations.submitBtn.text': '전송',
  'consultations.sex.title': '성별',
  'consultations.sex.male.text': '남자',
  'consultations.sex.female.text': '여자',
  'consultations.email.valid.message': '유효하지 않는 이메일 형식입니다',
  'consultations.birthday.valid.message': '유효하지 않은 생년월일 형식입니다',

  'consultations.is.plastuc.surgery.detail.title': '수술명과 날짜 적어주세요',
  'consultations.is.disease.detail.title': '병명을 적어주세요',
  'consultations.is.medicine.detail.title': '약 이름을 적어주세요',
  'consultations.is.trip.korea.detail.title': '시기를 적어주세요',
  'consultations.hospital.in.korea.detail.title': '병원명을 적어주세요',

  'consultations.personal.info.title': '💡잠시만요, 마지막으로 개인정보 확인 부탁드립니다!',
  'consultations.personal.info.subTitle': '이메일이나 모바일을 통해서 진행 상황을 계속 알려드리겠습니다',

  'consultations.submit.success.alert.text': '제출되었습니다!',
};
