export const stringId = {
  'consultations.btnText': 'Konsultasi',
  'consultations.button.view.button.text': 'Konsultasi',
  'consultations.info.footer.button.text': 'Permohonan untuk berpartisipasi',

  //----- Home ----- //

  'home.banner.subTitle':
    'One Stop Solution Mulai Dari Melihat Review, Melakukan Konsultasi Sampai Perawatan Pasca Operasi',
  'home.partners.logo.title':
    'Bekerja sama dengan lebih dari 500 rumah sakit serta klinik di Korea Selatan dan Indonesia.',
  'home.influencer.title': 'MyVenus x Influencer',
  'home.influencer.descrption1':
    'Awal tau myvenus dari kerabat saya karena banyak yang pergi untuk surgery bareng myvenus, jadi buat saya lebih percaya pastinya. Dan memang terbukti hasil luar biasa  dan memuaskan. Thanks banget pokonya buat MyVenus!',
  'home.influencer.descrption2':
    'I love myvenus service by the way. Jadi tuh mereka literally menerbangkan dokternya biar kita bisa ketemu langsung. Jadi semakin yakin dan percaya deh sama pilihan rumah sakit plus dokternya!',
  'home.influencer.descrption3':
    'Thankyou MyVenus udah ngebantu mama untuk dapet pelayanan yang terbaik selama di Korea!',
  'home.influencer.descrption4':
    'Thank you sama MyVenus sudah dibawa ke Korea, ditemenin sama bosnya juga. Bosnya humble banget. Pokonya aku ngerasa terbantu banget sama MyVenus ini',
  'home.influencer.descrption5':
    'MyVenus bener-bener ngebantu aku banget dalam segala hal. Aku juga ditemenin sama translator loh. Jadi ga worry sama sekali. Kalo ada apa apa mereka bisa langsung bantu urusin.',
  'home.influencer.descrption6':
    'Kami pake jasa MyVenus kali ini! Mereka itu medical tourism service provider yang servicenya lengkap. CEOnya juga dokter jadi lebih terpercaya & mereka kerja sama dengan rumah sakit top tier Korea only! ',
  'influencer.contentsLink.text': 'Lihat lebih detail',
  'home.service.reviewList.title': 'Banyak client telah menggunakan layanan MyVenus',
  'home.service.reviewList.subTitle': 'MyVenus dapat memudahkan segala persiapan yang dibutuhkan untuk surgery',
  'home.myvenusApp.list.title': 'Fitur Utama Aplikasi MyVenus',
  'home.myvenusApp.list.image.title1': 'Membandingkan berbagai macam informasi dokter dan rumah sakit.',
  'home.myvenusApp.list.image.description1':
    'Banyak client telah menggunakan layanan MyVenus. MyVenus dapat memudahkan segala persiapan yang dibutuhkan untuk surgery.',
  'home.myvenusApp.list.image.title2': 'Real Review Cosmetic Surgery',
  'home.myvenusApp.list.image.description2':
    'Lihat real review cosmetic surgery untuk setiap prosedur dan bagian operasi.',
  'home.myvenusApp.list.image.title3': 'Konsultasi untuk Cosmetic Surgery',
  'home.myvenusApp.list.image.description3':
    'Dapatkan layanan konsultasi yang mendetail dengan dokter atau rumah sakit pilihan Anda melalui MyVenus.',
  'home.myvenusApp.list.image.title4': 'Promo',
  'home.myvenusApp.list.image.description4': 'Lihat berbagai macam promo / spesial event dari setiap rumah sakit.',
  'home.conciergeService.view.title': 'Layanan Medical Concierge yang Eksklusif',
  'home.conciergeService.view.content.text1':
    'Mau pergi ke Korea untuk operasi plastik, tapi gatau harus mulai dari mana?\nPengen cari dokter terbaik? Takut sama efek samping setelah operasi?',
  'home.conciergeService.view.content.text2':
    'Demi memastikan keberhasilan operasi bagi para client kami, MyVenus menyediakan Layanan Medical Concierge yang Eksklusif.',
  'home.conciergeService.view.btn.text': 'Lihat lebih detail',
  'home.dvs.introduction.list.title':
    'MyVenus hanya bekerja sama dengan dokter dan rumah sakit yang sudah melalui tahap verifikasi DVS (Doctor Verification Sistem) yang ketat.',
  'home.dvs.introduction.list.title1': 'Pemeriksaan Staff Medis',
  'home.dvs.introduction.list.description1':
    'Kami memeriksa apakah staff medis ahli bedah plastik (bukan dokter umum biasa) dengan pengalaman lebih dari 5 tahun dan memeriksa apakah ada kecelakaan medis sebelumnya',
  'home.dvs.introduction.list.title2': 'Latar belakang Staff Medis',
  'home.dvs.introduction.list.description2':
    'Periksa surat surat dari universitas kedokteran, sertifikasi dari bidang bedah khusus, kegiatan seminar medis dan asosiasi medis laiinya',
  'home.dvs.introduction.list.title3': 'Fasilitas medis darurat rumah sakit',
  'home.dvs.introduction.list.description3':
    'Kami memeriksa apakah ahli anastesi tinggal di department bedah plastik dan apakah ada peralatan medis darurat',
  'home.dvs.introduction.list.title4': 'Pemeriksaan Fasilitas Rumah Sakit',
  'home.dvs.introduction.list.description4':
    'Kami memeriksa apakah alat kesehatan dan obat yang digunakan di Rumah Sakit tersebut resmi disetujui oleh Kementerian Kesehatan dan Kesejahteraan atau menggunakan dosis yang benar.',
  'home.consultations.button.view.title': 'Coba Konsultasi sekarang juga!',

  //----- conciergeService ----- //
  'conciergeService.about.title':
    'Memperkenalkan Layanan Medical Concierge MyVenus yang Eksklusif dan Berbeda dari pada yang lain.',
  'conciergeService.about.title.split.text': 'Layanan Medical Concierge MyVenus yang Eksklusif',
  'conciergeService.about.msg.bubble.text1':
    '"Mau pergi ke Korea untuk operasi plastik, tapi gatau harus mulai dari mana?"',
  'conciergeService.about.msg.bubble.text2':
    '"Khawatir apa yang kita inginkan gabisa tersampaikan dengan baik ke dokternya karena masalah kendala bahasa?"',
  'conciergeService.about.msg.bubble.text3': '"Setelah operasi di korea butuh bantuan?"',
  'conciergeService.about.msg.bubble.text4':
    '"Kalo udah pulang ke Indonesai dan terjadi masalah bingung harus gimana?"',
  'conciergeService.serviceTrust.induction.view.text1':
    'Kami bertanggung jawab terhadap semua client kami mulai dari sebelum hingga sesudah operasi.',
  'conciergeService.serviceTrust.induction.view.text2':
    'MyVenus memiliki perusahaan dan izin yang terdaftar secara legal di Indonesia & di Korea.\nTidak bisa dibandingkan dengan perusahaan lainnya.',
  'conciergeService.ServiceProcedure.view.split.title': 'One-stop Solution untuk Cosmetic Surgery',
  'conciergeService.ServiceProcedure.view.title':
    'Mulai dari konsultasi dengan dokter Korea, reservasi operasi, pengajuan visa, hingga perawatan rutin setelah operasi\nMenyediakan One-stop Solution untuk Cosmetic Surgery',
  'conciergeService.ServiceProcedure.view.item.text1': 'Konsultasi Online/Offline dengan Layanan Penerjemah',
  'conciergeService.ServiceProcedure.view.item.text2': 'Membuat Reservasi untuk operasi plastik.',
  'conciergeService.ServiceProcedure.view.item.text3': 'Penerbitan Penawaran Harga/ Biaya Operasi Setelah Konsultasi',
  'conciergeService.ServiceProcedure.view.item.text4': 'Pembayaran DP',
  'conciergeService.ServiceProcedure.view.item.text5': 'Pembuatan Visa Korea',
  'conciergeService.ServiceProcedure.view.item.text6': 'Reservasi Tiket Pesawat & Hotel',
  'conciergeService.ServiceProcedure.view.item.text7': 'Sampai di Korea',
  'conciergeService.ServiceProcedure.view.item.text8': 'Layanan Penjemputan di Bandara Korea',
  'conciergeService.ServiceProcedure.view.item.text9': 'Layanan Penerjemah Profesional',
  'conciergeService.ServiceProcedure.view.item.text10': 'Perawatan Intensif Pasca Operasi',
  'conciergeService.ServiceProcedure.view.item.text11': 'Berangkat dari Indonesia',
  'conciergeService.ServiceProcedure.view.item.text12': 'Pengurusan Segala Layanan Administrasi di Indonesia',
  'conciergeService.service.reviewList.title':
    'Banyak client telah menggunakan layanan MyVenus. MyVenus dapat memudahkan segala persiapan yang dibutuhkan untuk surgery.',
  'conciergeService.consultations.button.view.title':
    'Layanan Medical Concierge MyVenus bukan sebuah pilihan, melainkan suatu keharusan!\nKonsultasi dengan MyVenus tentang operasi plastik sekarang juga!',

  //----- consultations ----- //
  'consultations.title': 'Medical Form for Consultation Event',
  'consultations.lite.title': 'Medical Form',
  'consultations.subTitle':
    'Setelah mengisi Medical Form, Reservasi Anda hanya akan terkonfirmasi jika Anda melampirkan gambar KTP dan membayar biaya pendaftaran sebesar Rp.1,000,000.',
  'consultations.lite.subTitle':
    'Setelah ini Anda akan berkonsultasi dengan konselor dari MyVenus lalu kami akan membantu Anda untuk berkonsultasi dengan rumah sakit/dokter Korea yang diinginkan!',
  'consultations.info.text':
    '💡Terimakasih telah mendaftar Consultation Event kami! Setelah Anda selesai mengisi Medical Form ini, kami akan mengirimkannya terlebih dahulu kepada Dokter sebagai referensi. Agar kualitas konsultasi Anda terjaga dengan baik, mohon bantuannya untuk melengkapi data di Medical Form!',

  'consultations.wish.surgical.site.title': 'Operasi yang diinginkan',
  'consultations.wish.surgical.site.eyes': 'Eyes',
  'consultations.wish.surgical.site.nose': 'Nose',
  'consultations.wish.surgical.site.facialContouring': 'Facial Contouring',
  'consultations.wish.surgical.site.breast': 'Breast',
  'consultations.wish.surgical.site.antiAging': 'Anti-aging',
  'consultations.wish.surgical.site.body': 'Body',
  'consultations.wish.surgical.site.hair': 'Hair',
  'consultations.wish.surgical.site.nonSurgical': 'Non-surgical',

  'consultations.is.plastuc.surgery.title': 'Apakah sudah melakukan operasi plastik (Termasuk botox & FIller)?',
  'consultations.upload.surgical.result.title': 'Unggah referensi foto hasil operasi yang diinginkan',
  'consultations.upload.face.title': 'Mohon unggah foto wajah Anda dari 3 sisi seperti contoh berikut',
  'consultations.upload.face.halfSide': ' Tampak 45 derajat',
  'consultations.upload.face.front': 'Tampak Depan',
  'consultations.upload.face.side': 'Tampak Samping',
  'consultations.is.disease.title': 'Apakah ada riwayat penyakit tertentu?',
  'consultations.is.medicine.title': 'Apakah ada obat yang sedang diminum secara rutin? atau karena penyakit tertentu?',
  'consultations.is.trip.korea.title': 'Apakah ada rencana untuk mengunjungi Korea?',
  'consultations.hospital.in.korea.title':
    'Apakah ada rumah sakit di Korea yang diminati? atau pernah melakukan konsultasi sebelumnya dengan rumah sakit tersebut?',
  'consultations.source.myvenus.title': 'Anda mengetahui MyVenus dari mana?',
  'consultations.input.content.placeholder': 'Silahkan isi',
  'consultations.email.placeholder': 'Masukan Alamat Email',
  'consultations.name.title': 'Nama',
  'consultations.name.placeholder': 'Masukan Nama Lengkap (Sesuai dengan Nama yang ada di Paspor)',
  'consultations.phone.title': 'Nomor Telepone',
  'consultations.phone.placeholder': 'Masukan Nomor Telepone',
  'consultations.birthday.title': 'Tanggal Lahir',
  'consultations.birthday.placeholder': 'Masukan Tanggal Lahir',
  'consultations.submitBtn.text': 'Submit',
  'consultations.sex.title': 'Jenis Kelamin',
  'consultations.sex.male.text': 'Laki-laki',
  'consultations.sex.female.text': 'Perempuan',
  'consultations.email.valid.message': 'Format email salah',
  'consultations.birthday.valid.message': 'Format tahun, bulan dan tanggal lahir salah',

  'consultations.is.plastuc.surgery.detail.title': 'Tuliskan nama dan tanggal operasinya',
  'consultations.is.disease.detail.title': 'Tuliskan nama penyakit yang diderita',
  'consultations.is.medicine.detail.title': 'Tuliskan nama obat yang sedang di konsumsi',
  'consultations.is.trip.korea.detail.title': 'Tuliskan waktu',
  'consultations.hospital.in.korea.detail.title': 'Tuliskan nama rumah sakit',

  'consultations.personal.info.title': '💡Tunggu, mohon konfirmasi detail pribadi Anda untuk terakhir kalinya!',
  'consultations.personal.info.subTitle':
    'Kami akan terus mengabari Anda mengenai perkembangan Anda melalui email atau ponsel',

  'consultations.submit.success.alert.text': 'Dikirim!',
};
