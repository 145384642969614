import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { throttle } from "../../../libs/throttle";
import { debounce } from "../../../libs/debounce";
import images from "../../../assets/images";
import NavbarToggle from "./NavBarToggle";
import SmallButton from "../../atoms/button/SmallButton";
import { theme } from "../../../styles/theme";
import MVText from "../../atoms/text/MVText";
import { useLocalizations } from "../../../localizations";

import NationSelectButton from "./NationSelectButton";
import NavBarItem from "./NavBarItem"; // 파일명 수정

const NavBar = ({
  language,
  countryCode,
  isEvent = false,
  isFixedColor = false,
}) => {
  const { Lang } = useLocalizations(countryCode);

  const NAV_MAP = useMemo(() => {
    const arr = [
      {
        title: "Service",
        url: countryCode ? `/${countryCode}/service` : "/service",
        type: "route",
      },
    ];

    if (isEvent) {
      arr.push({
        title: "Event",
        url: "/consultations-info",
        type: "route",
      });
    }

    arr.push(
      ...[
        {
          title: "Trend",
          url: "/trend",
          type: "new",
        },
        {
          title: "Journal",
          url: "/journal",
          type: "new",
        },
        {
          title: "Contact Us",
          url: "/contact",
          type: "route",
        },
      ]
    );

    if (language?.startsWith("ko")) {
      arr.push({
        title: "한국병원 입점신청",
        url: "/korea",
        type: "route",
      });
    }
    return arr;
  }, [language, countryCode, isEvent]);

  const [navbarCollapsed, setNavbarCollapsed] = useState(true);
  const [isNavBarTransparent, setIsNavBarTransparent] = useState(true);

  const isTransparent = useMemo(
    () => isNavBarTransparent && !isFixedColor,
    [isNavBarTransparent, isFixedColor]
  );

  const handleScroll = useCallback(() => {
    if (window.pageYOffset > 500) {
      setIsNavBarTransparent(false);
    } else {
      setIsNavBarTransparent(true);
    }
    setNavbarCollapsed(true);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", throttle(debounce(handleScroll)));
    return () =>
      window.removeEventListener("scroll", throttle(debounce(handleScroll)));
  }, []);

  return (
    <Nav
      mobileNavbarCollapsed={navbarCollapsed}
      isNavBarTransparent={isTransparent}
    >
      <Wrap>
        <NavBarWrap>
          <MobileNavbar>
            {!navbarCollapsed ? (
              <NationSelectButton
                boxStyle={{ backgroundColor: "transparent" }}
                countryCode={countryCode}
                setNavbarCollapsed={setNavbarCollapsed}
              />
            ) : (
              <div>
                <Logo
                  src={images.imgColorfulLogo}
                  alt="MyVenus Logo"
                  width={144}
                  height={26}
                />
                <MobileLogo
                  src={
                    isTransparent && navbarCollapsed
                      ? images.imgWhiteLogo
                      : images.imgColorfulLogo
                  }
                  alt="MyVenus Logo"
                  width={100}
                  height={18}
                />
              </div>
            )}
            <NavbarToggleBox>
              {/* <NavbarToggle
                onClickMethod={setNavbarCollapsed}
                isNavBarTransparent={isTransparent}
                setIsNavBarTransparent={setIsNavBarTransparent}
                collapsed={navbarCollapsed}
              /> */}
            </NavbarToggleBox>
          </MobileNavbar>
          <NavbarListsWrap mobileNavbarCollapsed={navbarCollapsed}>
            <NavbarListsArea></NavbarListsArea>
          </NavbarListsWrap>
          <RightSideBox>
            {/* <NationSelectButton
              boxStyle={{ marginRight: 20 }}
              countryCode={countryCode}
            /> */}
            <SmallButton
              style={{ padding: "6px 40px" }}
              text={
                <MVText type="button_1" color="defaultBackground">
                  {Lang("consultations.btnText")}
                </MVText>
              }
              fontColor={theme.colors.defaultBackground}
              backgroundColor={theme.colors.primaryMain}
              onClick={() => {
                window.open("https://wa.me/+821022800284", "_blank");
              }}
            />
          </RightSideBox>
        </NavBarWrap>
      </Wrap>
    </Nav>
  );
};

const Nav = styled.nav`
  transition: background 0.2s ease 0.1s;
  position: fixed;
  width: 100%;
  z-index: 5;
  background-color: ${({ theme }) => theme.colors.defaultBackground};
  @media only screen and (max-width: 1024px) {
    background-color: ${({
      mobileNavbarCollapsed,
      isNavBarTransparent,
      theme,
    }) =>
      mobileNavbarCollapsed
        ? isNavBarTransparent
          ? "transparent"
          : theme.colors.defaultBackground
        : theme.colors.defaultBackground};
  }
`;

const Wrap = styled.div`
  width: calc(100vw - 10%);
  margin-right: auto;
  margin-left: auto;
  @media only screen and (max-width: 1024px) {
    width: auto;
  }
`;

const NavBarWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 98px;
  @media only screen and (max-width: 1024px) {
    display: block;
    padding: 18px 20px;
    height: 100%;
  }
`;

const Logo = styled.img`
  display: block;
  @media only screen and (max-width: 1024px) {
    display: none;
  }
`;

const MobileLogo = styled.img`
  display: none;
  @media only screen and (max-width: 1024px) {
    display: block;
  }
`;

const MobileNavbar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const NavbarListsWrap = styled.div`
  overflow: hidden;
  z-index: 1000;
  transition: max-height 0.2s ease-in-out;
  @media only screen and (max-width: 1024px) {
    max-height: ${({ mobileNavbarCollapsed }) =>
      mobileNavbarCollapsed ? 0 : " 334px"};
  }
`;

const NavbarListsArea = styled.div`
  display: "flex";
  z-index: 1000;
  @media only screen and (max-width: 1024px) {
    display: block;
    padding-top: 22px;
    padding-bottom: 22px;
  }
`;

const NavbarToggleBox = styled.div`
  display: none;
  @media only screen and (max-width: 1024px) {
    display: block;
  }
`;

const RightSideBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  @media only screen and (max-width: 1024px) {
    display: none;
  }
`;

export default memo(NavBar);
