import { theme } from "../styles/theme";
import toast from "react-hot-toast";

export const newOpenLink = (url) => window.open(url);

export const onShareEvent = async (
  title = "MyVenus",
  content = "My Beauty Assistant",
  shareUrl
) => {
  try {
    if (window?.navigator?.share) {
      window?.navigator.share({
        title,
        text: content,
        url: shareUrl,
      });
    } else {
      await window?.navigator?.clipboard?.writeText(shareUrl);
      toast.success("Copy!", {
        position: "bottom-center",
        style: {
          backgroundColor: theme.colors.primaryMain,
          color: theme.colors.defaultBackground,
          fontFamily: "Pretendard-Regular",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: 14,
        },
      });
    }
  } catch (error) {}
};

export const getUtmParams = (query) => {
  if (query && query.utm_source && query.utm_campaign) {
    const { utm_source, utm_campaign } = query;
    return { utm_source, utm_campaign };
  }
  return {};
};
