import MVText from "../../../components/atoms/text/MVText";
import React, { memo } from "react";
import styled from "styled-components";

const DVSIntroductionListItem = ({ data }) => {
  const { title, description, img, imgAlt } = data ?? {};

  return (
    <Wrap>
      <Area>
        <ThumbnailImageBox>
          <ThumbnailImage src={img} alt={imgAlt} />
        </ThumbnailImageBox>
        <TitleBox>
          <MVText type="body_1_bold" color="BasicBlack" align="center">
            {title}
          </MVText>
        </TitleBox>
        <MVText
          style={{ color: "#646464" }}
          type="body_2_regular"
          align="center"
        >
          {description}
        </MVText>
      </Area>
    </Wrap>
  );
};

export default memo(DVSIntroductionListItem);

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  :not(:last-child) {
    margin-right: 106px;
  }
  @media screen and (max-width: 1440px) {
    width: 50%;
    :not(:last-child) {
      margin-right: 0px;
    }
    :nth-child(1) {
      margin-bottom: 40px;
    }
    :nth-child(2) {
      margin-bottom: 40px;
    }
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    margin-bottom: 40px;
  }
`;

const Area = styled.div`
  display: flex;
  flex-direction: column;
  width: 280px;
`;

const ThumbnailImageBox = styled.div`
  overflow: hidden;
  margin-bottom: 40px;
  @media screen and (max-width: 768px) {
    margin-bottom: 20px;
  }
`;

const ThumbnailImage = styled.img`
  width: 280px;
  height: 280px;
  border-radius: 280px;
  object-fit: cover;
`;

const TitleBox = styled.div`
  text-align: center;
  margin-bottom: 20px;
  @media screen and (max-width: 768px) {
    margin-bottom: 10px;
  }
`;
