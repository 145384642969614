import React, { memo } from "react";
import styled from "styled-components";
import MVText from "../../../components/atoms/text/MVText";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import { useLocalizations } from "../../../localizations";
import images from "../../../assets/images";

const InfluencerListItem = ({ data, countryCode }) => {
  const { name, descrption, linkUrl, img } = data ?? {};

  const isMobile = useMediaQuery(768);
  const { Lang } = useLocalizations(countryCode);

  return (
    <Wrap>
      <InfluencerImageBox>
        <InfluencerImage src={img} alt="Influencer-Image" />
      </InfluencerImageBox>
      <InfluencerInfoBox>
        <MVText
          style={{ marginBottom: 20 }}
          type={isMobile ? "body_2_bold" : "subtitle_3_bold"}
          color="BasicBlack"
        >
          {name}
        </MVText>
        <MVText
          style={{ marginBottom: 20 }}
          type={isMobile ? "body_4_regular" : "body_2_regular"}
          color="BasicBlack"
        >
          {descrption}
        </MVText>
        <ContentLinkBox onClick={() => window.open(linkUrl)}>
          <MVText
            style={{ marginRight: 10 }}
            type="button_1"
            color="BasicBlack"
          >
            {Lang("influencer.contentsLink.text")}
          </MVText>
          <ContentLinkArrowIcon
            src={images.iconRightArrow}
            alt="ContentLink-ArrowIcon"
          />
        </ContentLinkBox>
      </InfluencerInfoBox>
    </Wrap>
  );
};

export default memo(InfluencerListItem);

const Wrap = styled.li`
  flex: 0 0 calc(33.33% - 24px);
  padding: 0px 12px;
  @media screen and (max-width: 768px) {
  }
`;

const InfluencerImageBox = styled.div`
  overflow: hidden;
  border-radius: 25px;
`;

const InfluencerImage = styled.img`
  width: 100%;
  height: auto;
  object-fit: contain;
  @media screen and (max-width: 768px) {
    width: 200px;
    height: 200px;
  }
`;

const InfluencerInfoBox = styled.div`
  margin-top: 20px;
`;

const ContentLinkBox = styled.a`
  display: flex;
  align-items: center;
  padding-bottom: 56px;
  cursor: pointer;
`;

const ContentLinkArrowIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-bottom: 1px;
`;
