import { createGlobalStyle } from "styled-components";

const customMediaQuery = (maxWidth) => `@media (max-width: ${maxWidth}px)`;

export const media = {
  pc: customMediaQuery(1440),
  tablet: customMediaQuery(768),
  mobile: customMediaQuery(576),
};

const colors = {
  primaryMain: "#7a23df",
  BasicBlack: "#000000",
  grayScale700: "#6f6f6f",
  grayScale600: "#8e8e8e",
  grayScale500: "#aeadb0",
  grayScale400: "#c6c4c8",
  grayScale900: "#2d2b2f",
  basicSubBasicBlack: "#100f10",
  grayScale800: "#4e4d50",
  grayScale1000: "#161517",
  grayScale300: "#dcd9de",
  grayScale200: "#efedf0",
  grayScale100: "#f9f8fa",
  primary800: "#5c0eb6",
  primary900: "#410685",
  primary700: "#7A23DF",
  primary600: "#9c53f1",
  primary601: "#994cf3",
  primary500: "#b272fd",
  primary501: "#b06bff",
  primary400: "#c696ff",
  primary300: "#dabbff",
  primary200: "#ebdbff",
  primary100: "#f8f1ff",
  error400: "#f96491",
  error300: "#f981a5",
  error200: "#fabbce",
  error100: "#ffe5ed",
  accent100: "#e0fceb",
  accent200: "#a2f4c3",
  accent300: "#62f09b",
  accent400: "#40dc7e",
  secondary400: "#fa4c80",
  secondary500: "#fa2968",
  watermelon: "#f84a69",
  softblue: "#546ef6",
  ongrayScale300: "#2D2D2D",
  ongrayScale200: "#333333",
  defaultBackground: "#ffffff",
};

const fonts = {
  h_1_bold: `
      font-family: Poppins-Bold;
      font-style: normal;
      font-weight: 700;
      font-size: 66px;
      line-height: 90px;
    `,
  h_2_bold: `
        font-family: Poppins-Bold;
        font-style: normal;
        font-weight: 700;
        font-size: 56px;
        line-height: 78px;
      `,
  h_3_bold: `
        font-family: Poppins-Bold;
        font-style: normal;
        font-weight: 700;
        font-size: 28px;
        line-height: 42px;
      `,
  body_1_bold: `
        font-family: Poppins-Bold;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 26px;
      `,
  body_1_regular: `
        font-family: Poppins-Regular;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 26px;
      `,
  body_2_regular: `
      font-family: Poppins-Regular;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    `,
  body_2_bold: `
        font-family: Poppins-Bold;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
      `,
  body_2_medium: `
        font-family: Poppins-Medium;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
      `,
  body_3_regular: `
      font-family: Poppins-Regular;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    `,
  body_3_bold: `
      font-family: Poppins-Bold;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
    `,
  body_3_medium: `
      font-family: Poppins-Medium;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
    `,
  body_4_regular: `
      font-family: Poppins-Regular;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
    `,
  body_4_bold: `
      font-family: Poppins-Bold;
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 18px;
    `,
  body_5_regular: `
    font-family: Poppins-Regular;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
  `,
  subtitle_1_bold: `
      font-family: Poppins-Bold;
      font-style: normal;
      font-weight: 700;
      font-size: 40px;
      line-height: 58px;
    `,
  subtitle_2_bold: `
      font-family: Poppins-Bold;
      font-style: normal;
      font-weight: 700;
      font-size: 30px;
      line-height: 40px;
    `,
  subtitle_3_medium: `
      font-family: Poppins-Medium;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 36px;
    `,
  subtitle_3_bold: `
      font-family: Poppins-Bold;
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 36px;
    `,
  subtitle_3_regular: `
      font-family: Poppins-Regular;
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 36px;
    `,
  subtitle_4_bold: `
    font-family: Poppins-Bold;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    `,
  button_1: `
    font-family: Poppins-Bold;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 30px;
    `,
  button_2: `
    font-family: Poppins-Bold;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 26px;
    `,
};

const mobileFonts = {
  h_5_extrabold: `
    font-family: Pretendard-ExtraBold;
    font-style: normal;
    font-weight: 800;
    font-size: 28px;
    line-height: 36px;
    `,
  body_1_extrabold: `
    font-family: Pretendard-ExtraBold;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
    `,
  body_1_bold: `
    font-family: Pretendard-Bold;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
  `,
  body_1_medium: `
    font-family: Pretendard-Medium;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
  `,
  body_2_extrabold: `
  font-family: Pretendard-ExtraBold;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 18px;
  `,
  body_2_bold: `
    font-family: Pretendard-Bold;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
  `,
  body_2_medium: `
    font-family: Pretendard-Medium;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
  `,
  body_2_regular: `
    font-family: Pretendard-Regular;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
  `,
  body_3_bold: `
    font-family: Pretendard-Bold;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
  `,
  body_3_medium: `
    font-family: Pretendard-Medium;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
  `,
  body_3_regular: `
    font-family: Pretendard-Regular;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
  `,
  body_3_extrabold: `
    font-family: Pretendard-ExtraBold;
    font-style: normal;
    font-weight: 800;
    font-size: 12px;
    line-height: 16px;
    `,
  body_4_regular: `
    font-family: Pretendard-Regular;
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 15px;
  `,
  body_5_bold: `
    font-family: Pretendard-Bold;
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 14px;
  `,
  body_5_regular: `
    font-family: Pretendard-Regular;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
  `,
  body_5_medium: `
    font-family: Pretendard-Medium;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 14px;
  `,
  subtitle_1_medium: `
    font-family: Pretendard-Medium;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    `,
};

export const theme = {
  colors,
  fonts,
  mobileFonts,
  media,
};

export const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: Poppins, Pretendard, sans-serif;
  }
`;

export const FontTypes = Object.keys(fonts);
export const MobileFontTypes = Object.keys(mobileFonts);
export const ColorTypes = Object.keys(colors);
export const MediaTypes = Object.keys(media);
