import React, { useMemo } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const MVText = ({ type, mobileType, color, style, align, children }) => {
  const textKey = type || "body_1_bold";
  const colorKey = color || "BasicBlack";
  const textAlign = useMemo(() => {
    switch (align) {
      case "center":
        return "center";
      case "right":
        return "right";
      case "left":
      default:
        return "left";
    }
  }, [align]);
  return (
    <Text
      text={textKey}
      mobileText={mobileType}
      color={colorKey}
      style={{ ...style, textAlign }}
    >
      {children}
    </Text>
  );
};

MVText.propTypes = {
  type: PropTypes.string,
  mobileType: PropTypes.string,
  color: PropTypes.string,
  align: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.node,
};

const Text = styled.span`
  display: inline-block;
  color: ${({ theme, color }) => theme.colors[color]};
  ${({ theme, text, mobileText }) =>
    mobileText ? theme.mobileFonts[mobileText] : theme.fonts[text]}
`;

export default MVText;
