import React, { memo } from "react";
import styled from "styled-components";

const MediumButton = ({
  style,
  icon,
  text,
  backgroundColor,
  fontColor,
  onClick,
  isHoverAnim,
  disabled,
}) => {
  if (isHoverAnim) {
    return (
      <AnimButton
        style={style}
        backgroundColor={backgroundColor}
        fontColor={fontColor}
        activeFontColor={isHoverAnim?.activeFontColor}
        activeBgColor={isHoverAnim?.activeBgColor}
        onClick={onClick}
      >
        <ContentWrap>
          {icon}
          {text}
        </ContentWrap>
      </AnimButton>
    );
  }
  return (
    <Button
      type="button"
      style={style}
      backgroundColor={backgroundColor}
      fontColor={fontColor}
      onClick={onClick}
      disabled={disabled}
    >
      <ContentWrap>
        {icon}
        {text}
      </ContentWrap>
    </Button>
  );
};

const Button = styled.button`
  display: flex;
  padding: 10px 50px;
  border-radius: 50px;
  border: none;
  color: ${({ fontColor, theme }) =>
    fontColor ? fontColor : theme.colors.BasicBlack};
  background-color: ${({ theme, backgroundColor }) =>
    backgroundColor ? backgroundColor : theme.colors.defaultBackground};
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const AnimButton = styled.button`
  display: flex;
  padding: 10px 50px;
  border-radius: 50px;
  border: none;
  color: ${({ fontColor, theme }) =>
    fontColor ? fontColor : theme.colors.BasicBlack};
  background-color: ${({ theme, backgroundColor }) =>
    backgroundColor ? backgroundColor : theme.colors.defaultBackground};
  cursor: pointer;
  transition-duration: 0.5s;
  -webkit-transition-property: color, background-color;
  transition-property: color, background-color;
  &:hover,
  &:focus,
  &:active {
    -webkit-animation-name: hvr-back-pulse;
    animation-name: hvr-back-pulse;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-delay: 0.5s;
    animation-delay: 0.5s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    background-color: ${({ theme, activeBgColor }) =>
      activeBgColor ? activeBgColor : theme.colors.primaryMain};
    color: ${({ theme, activeFontColor }) =>
      activeFontColor ? activeFontColor : theme.colors.defaultBackground};
  }
`;

const ContentWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default memo(MediumButton);
