import React, { memo } from "react";
import styled from "styled-components";
import MVText from "../../../components/atoms/text/MVText";
import { useLocalizations } from "../../../localizations";
import images from "../../../assets/images";

const InfluencerSwipeItem = ({ data, countryCode }) => {
  const { name, descrption, linkUrl, img } = data ?? {};

  const { Lang } = useLocalizations(countryCode);

  return (
    <Wrap>
      <InfluencerImageBox>
        <InfluencerImage src={img} alt="Influencer-Image" />
      </InfluencerImageBox>
      <InfluencerInfoBox>
        <MVText
          style={{ marginBottom: 20 }}
          type={"body_2_bold"}
          color="BasicBlack"
        >
          {name}
        </MVText>
        <MVText
          style={{ marginBottom: 20 }}
          type={"body_4_regular"}
          color="BasicBlack"
        >
          {descrption}
        </MVText>
        <ContentLinkBox onClick={() => window.open(linkUrl)}>
          <MVText
            style={{ marginRight: 10 }}
            type="button_1"
            color="BasicBlack"
          >
            {Lang("influencer.contentsLink.text")}
          </MVText>
          <ContentLinkArrowIcon
            src={images.iconRightArrow}
            alt="ContentLink-ArrowIcon"
          />
        </ContentLinkBox>
      </InfluencerInfoBox>
    </Wrap>
  );
};

export default memo(InfluencerSwipeItem);

const Wrap = styled.li`
  width: 200px;
`;

const InfluencerImageBox = styled.div`
  overflow: hidden;
  border-radius: 25px;
`;

const InfluencerImage = styled.img`
  width: 200px;
  height: 200px;
  object-fit: cover;
`;

const InfluencerInfoBox = styled.div`
  margin-top: 20px;
`;

const ContentLinkBox = styled.a`
  display: flex;
  align-items: center;
  padding-bottom: 56px;
  cursor: pointer;
`;

const ContentLinkArrowIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-bottom: 1px;
`;
