import React, { useMemo } from "react";
import styled from "styled-components";

const Row = ({ align, justify, style, children }) => {
  const alignItems = useMemo(() => {
    switch (align) {
      case "start":
      case "end":
      case "center":
        return align;
      default:
        return "start";
    }
  }, [align]);

  const justifyContent = useMemo(() => {
    switch (justify) {
      case "around":
        return "space-around";
      case "between":
        return "space-between";
      case "end":
        return "flex-end";
      case "center":
        return "center";
      case "start":
      default:
        return "flex-start";
    }
  }, [justify]);

  return (
    <RowWrapper style={{ ...style, justifyContent, alignItems }}>
      {children}
    </RowWrapper>
  );
};

const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export default Row;
