import { memo } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const NavbarItem = ({ title, url, type }) => {
  if (type === "route") {
    return <NavRouteButton to={url}>{title}</NavRouteButton>;
  }

  if (type === "new") {
    return (
      <NavLinkButton as="button" onClick={() => window.open(url, "_blank")}>
        {title}
      </NavLinkButton>
    );
  }

  return (
    <NavLinkButton as="a" href={url}>
      {title}
    </NavLinkButton>
  );
};

const NavLinkButton = styled.a`
  ${({ theme }) => theme.fonts.body_2_bold};
  color: ${({ theme }) => theme.colors.BasicBlack};
  padding: 0px 25px;
  &:hover {
    text-decoration: none;
  }
  cursor: pointer;
  @media only screen and (max-width: 1335px) {
    ${({ theme }) => theme.fonts.body_4_bold};
    padding: 0px 12px;
  }
  @media only screen and (max-width: 1024px) {
    ${({ theme }) => theme.fonts.body_3_bold};
    display: block;
    padding: 10px 0px;
  }
  /* @media only screen and (max-width: 768px) {
    ${({ theme }) => theme.fonts.body_2_bold};
    display: block;
    padding: 10px 0px;
  } */
`;

const NavRouteButton = styled(Link)`
  ${({ theme }) => theme.fonts.body_2_bold};
  color: ${({ theme }) => theme.colors.BasicBlack};
  padding: 0px 25px;
  &:hover {
    text-decoration: none;
  }
  @media only screen and (max-width: 1335px) {
    ${({ theme }) => theme.fonts.body_4_bold};
    padding: 0px 12px;
  }
  @media only screen and (max-width: 1024px) {
    ${({ theme }) => theme.fonts.body_3_bold};
    display: block;
    padding: 10px 0px;
  }
  /* @media only screen and (max-width: 768px) {
    ${({ theme }) => theme.fonts.body_2_bold};
    display: block;
    padding: 10px 0px;
  } */
`;

export default memo(NavbarItem);
