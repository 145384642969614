import React, { memo } from "react";
import styled from "styled-components";

const SmallButton = ({
  style,
  icon,
  text,
  backgroundColor,
  onClick,
  fontColor,
}) => {
  return (
    <Button
      type="button"
      style={style}
      backgroundColor={backgroundColor}
      fontColor={fontColor}
      onClick={onClick}
    >
      <ContentWrap>
        {icon}
        {text}
      </ContentWrap>
    </Button>
  );
};

const Button = styled.button`
  display: flex;
  padding: 8px 36px;
  border-radius: 50px;
  border: none;
  color: ${({ fontColor, theme }) =>
    fontColor ? fontColor : theme.colors.BasicBlack};
  background-color: ${({ theme, backgroundColor }) =>
    backgroundColor ? backgroundColor : theme.colors.defaultBackground};
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const ContentWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default memo(SmallButton);
